import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { useOnline } from '../../composables/use-online';

@Component({
  name: 'Online',
})
class Online extends Vue {
  public online = useOnline();
}

export { Online };

// TODO: remove this wrapper once we get rid of mixins and move to composition API everywhere
