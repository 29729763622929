import moment from 'moment';
import { defineStore } from 'pinia';
import { set } from 'vue';
import { AuthResponse } from '@/models/authResponse';
import { makeRequest } from '@/services//api-request';
import { Group } from '@/models/group';
import { GroupMembers } from '@/models/groupMembers';

export const useSessionsStore = defineStore('sessions', {
  state: () => ({
    sessions: [] as Array<Group>,
    groupMembers: {} as { [groupId: string]: GroupMembers },
    date: moment().format('YYYY-MM-DD'),
  }),
  actions: {
    async getSessionGroups() {
      const queryParams: Record<string, string> = {
        start_at_gt: this.date,
        start_at_lt: moment(this.date)
          .add(90, 'days')
          .format('YYYY-MM-DD'),
        sort: 'astart_at',
      };
      const authResponse: AuthResponse = await makeRequest('GET', `/groups?${new URLSearchParams(queryParams)}`);
      this.sessions = authResponse.body;
    },
    async getGroupMembers(groupId: number) {
      set(this.groupMembers, groupId, { groups: [], members: [], size: 0 });
      const result: Array<AuthResponse> = await Promise.all([
        makeRequest('GET', `/groups/${groupId}/groups`),
        makeRequest('GET', `/groups/${groupId}/members?without_child_groups=1`),
      ]);

      set(this.groupMembers[groupId], 'groups', result[0].body);
      set(this.groupMembers[groupId], 'members', result[1].body);
      const totalMembers = parseInt(result[1].response.headers.get('X-Total-Count') ?? '0', 10);
      set(
        this.groupMembers[groupId],
        'size',
        this.groupMembers[groupId].groups.reduce((sum, g) => sum + g.total_members, 0) + totalMembers
      );
    },
  },
});
