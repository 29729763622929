import { getCurrentInstance } from 'vue';

export const useAlert = () => {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('must be called in setup');
  }
  return vm.proxy.$alert;
};

export const useStore = () => {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('must be called in setup');
  }
  return vm.proxy.$store;
};

export const useTranslate = () => {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('must be called in setup');
  }
  return vm.proxy.$t.bind(vm.proxy);
};

export const useTranslatePlural = () => {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('must be called in setup');
  }
  return vm.proxy.$tc.bind(vm.proxy);
};

export const useConfirm = () => {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('must be called in setup');
  }
  return vm.proxy.$confirm;
};

export const useGtag = () => {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('must be called in setup');
  }
  return vm.proxy.$gtag;
};

export const useI18n = () => {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('must be called in setup');
  }
  return vm.proxy.$i18n;
};
