import { FilterOption } from '@/models/filterOption';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { MemberFilterState } from '@/models/memberFilterState';

/**
 * Filter state values for the members related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - MemberFilterState
 */
const getDefaultState = (): MemberFilterState => ({
  filter: '', // Formatted filter string applied to members network call.
  filterUpdateQueue: [] as Array<FilterOption>, // Filter selections not currently applied.
  filterState: [] as Array<FilterOption>, // Applied filters.
  selectedLevelFilterOptions: [] as Array<string>, // IDs of currently selected levels.
  selectedAgeFilterOptions: [] as Array<string>, // IDs of currently selected age ranges.
  selectedStatusFilterOptions: [] as Array<string>, // IDs of currently selected status options.
  selectedGroupedFilterOptions: null as string | null, // IDs of currently selected grouped status option.
  numberOfFiltersApplied: 0,
  selectedAgeMin: 0,
  selectedAgeMax: 99,
});

const state = getDefaultState();

/**
 * Vuex store mutations available for updating state values above.
 * These should be the only way state values are updated.
 * Should be synchronous transactions only to ensure predictability of state.
 *
 * @property mutations - Object
 */
const mutations = {
  resetState: (moduleState: MemberFilterState) => {
    Object.assign(moduleState, getDefaultState());
  },
  /**
   * Updates the stores filterState property.
   * This is the string included in the members requests.
   *
   * @param {MemberFilterState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @return void
   */
  updateFilter: (moduleState: MemberFilterState) => {
    let filterToApply = '';
    let filterStr = '';
    let numberOfAppliedFilters = 0;

    moduleState.filterState?.forEach(option => {
      switch (option.type) {
        case 'age':
          filterStr = option.id.replace('age', '&age[]=').replace('to', '-');
          break;
        case 'level':
          filterStr = '&level[]=' + option.id;
          break;
        case 'status':
          filterStr = '&status[]=' + option.id;
          break;
        case 'grouped':
          filterStr = '&in_group=' + option.id;
          break;
        default:
          filterStr = '';
          break;
      }
      if (option.on) {
        numberOfAppliedFilters++;
        filterToApply += filterStr;
      } else {
        filterToApply = filterToApply.replace(filterStr, '');
      }
    });
    moduleState.filter = filterToApply;
    moduleState.numberOfFiltersApplied = numberOfAppliedFilters;
  },

  /**
   * Updates the stores selectedLevelFilterOptions property.
   * This is the IDs of currently selected level filter options.
   *
   * @param {MemberFilterState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @param {Array<string>} selectedLevelFilterOptions
   * @return void
   */
  updateSelectedLevelFilterOptions: (moduleState: MemberFilterState, selectedLevelFilterOptions: Array<string>) => {
    moduleState.selectedLevelFilterOptions = selectedLevelFilterOptions;
  },

  /**
   * Updates the stores selectedAgeFilterOptions property.
   * This is the IDs of currently selected age filter options.
   *
   * @param {MemberFilterState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @param {Array<string>} selectedAgeFilterOptions
   * @return void
   */
  updateSelectedAgeFilterOptions: (moduleState: MemberFilterState, selectedAgeFilterOptions: Array<string>) => {
    moduleState.selectedAgeFilterOptions = selectedAgeFilterOptions;
  },
  /**
   * Updates the selected minimum age
   *
   * @param {MemberFilterState} moduleState
   * @param {number} age
   */
  updateSelectedAgeMin: (moduleState: MemberFilterState, age: number) => {
    moduleState.selectedAgeMin = age;
  },

  /**
   * Updates the selected max age
   *
   * @param {MemberFilterState} moduleState
   * @param {number} age
   */
  updateSelectedAgeMax: (moduleState: MemberFilterState, age: number) => {
    moduleState.selectedAgeMax = age;
  },

  /**
   * Updates the stores selectedStatusFilterOptions property.
   * This is the IDs of currently selected status filter options.
   *
   * @param {MemberFilterState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @param {Array<string>} selectedStatusFilterOptions
   * @return void
   */
  updateSelectedStatusFilterOptions: (moduleState: MemberFilterState, selectedStatusFilterOptions: Array<string>) => {
    moduleState.selectedStatusFilterOptions = selectedStatusFilterOptions;
  },

  /**
   * Updates the stores selectedGroupedFilterOptions property.
   * This is the IDs of currently selected grouped status of a members filter options.
   *
   * @param {MemberFilterState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @param {Array<string>} selectedGroupedFilterOptions
   * @return void
   */
  updateSelectedGroupedFilterOptions: (moduleState: MemberFilterState, selectedGroupedFilterOption: string) => {
    moduleState.selectedGroupedFilterOptions = selectedGroupedFilterOption;
  },

  /**
   * Updates the stores filter update queue property.
   * This is the change in filter selections since filters were last applied.
   *
   * @param {MemberFilterState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @param {Array<SchemeNodeModel>} filterOptions
   * @return void
   */
  updateFilterUpdateQueue: (moduleState: MemberFilterState, filterOptions: Array<FilterOption>) => {
    moduleState.filterUpdateQueue = filterOptions;
  },

  /**
   * Updates the stores filterState property.
   * This is the query string applied to any network call for members.
   *
   * @param {MemberFilterState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @param {Array<SchemeNodeModel>} filterOptions
   * @return void
   */
  updateFilterState: (moduleState: MemberFilterState, filterOptions: Array<FilterOption>) => {
    moduleState.filterState = filterOptions;
  },
};

/**
 * Available functions for handling business logic relating to this store modules state properties.
 * Can be asynchronous.
 * Interacts with the modules state properties via committing one or more synchronous mutations.
 * Actions receive an ActionContext which magically knows the local state and
 * something called root state which is the state in store/index.ts
 *
 * @property actions - Object
 */
const actions = {
  resetState: async (context: ActionContext<MemberFilterState, RootState>): Promise<void> => {
    context.commit('resetState');
  },

  /**
   * For the given filter type.
   * Changes in selection are selected options only, thus previously selected items are processed as no longer filtered on
   * and added to the queue.
   * The current selections are updated.
   * For each new selection the items are processed as filtered on and added to the queue.
   *
   * @param context
   * @param {{type: string, selectedOptions: Array<string>}} selectedFilterOptions
   * @return void
   */
  updateMemberFilterSelectedOptions: (
    context: ActionContext<MemberFilterState, RootState>,
    selectedFilterOptions: { type: string; selectedOptions: Array<string>; selectedOption: string }
  ) => {
    const selectedOptionTypeName =
      (`selected${selectedFilterOptions.type}FilterOptions` as 'selectedLevelFilterOptions') ||
      'selectedAgeFilterOptions';

    const previouslySelectedOptions = context.state[selectedOptionTypeName];

    // selectedGroupedFilterOptions is not an array
    if (selectedFilterOptions.selectedOption) {
      context.dispatch('updateMemberFilter', {
        id: previouslySelectedOptions, // v-treeview is set to have selections tracked by ID.
        type: selectedFilterOptions.type.toLowerCase(),
        on: false,
      });
      context.commit(`updateSelected${selectedFilterOptions.type}FilterOptions`, selectedFilterOptions.selectedOption);
      context.dispatch('updateMemberFilter', {
        id: selectedFilterOptions.selectedOption,
        type: selectedFilterOptions.type.toLowerCase(),
        on: true,
      });
      return;
    }

    // Set the previously selected level filter options in the update queue to false.
    if (previouslySelectedOptions?.length) {
      previouslySelectedOptions.forEach((selectedOption: string) => {
        context.dispatch('updateMemberFilter', {
          id: selectedOption, // v-treeview is set to have selections tracked by ID.
          type: selectedFilterOptions.type.toLowerCase(),
          on: false,
        });
      });
    }

    // Update selections
    context.commit(`updateSelected${selectedFilterOptions.type}FilterOptions`, selectedFilterOptions.selectedOptions);

    // Update the newly selected level filter options in the filter update queue.
    const newlySelectedOptions = context.state[selectedOptionTypeName];
    if (newlySelectedOptions?.length) {
      newlySelectedOptions.forEach((selectedOption: string) => {
        context.dispatch('updateMemberFilter', {
          id: selectedOption,
          type: selectedFilterOptions.type.toLowerCase(),
          on: true,
        });
      });
    }
  },

  /**
   * Processes individual FilterOptions.
   * Updates or adds to filter update queue.
   *
   * @param {ActionContext} context
   * @param {FilterOption} filterOption
   * @return void
   */
  updateMemberFilter: (context: ActionContext<MemberFilterState, RootState>, filterOption: FilterOption) => {
    const updateQueue = context.state.filterUpdateQueue;

    // Find if the option is currently in the filter state and update or add it accordingly.
    const index = updateQueue ? updateQueue.findIndex(option => option.id === filterOption.id) : -1;
    if (index < 0) {
      updateQueue.push(filterOption);
    } else {
      updateQueue[index] = filterOption;
    }
    context.commit('updateFilterUpdateQueue', updateQueue);
  },

  /**
   * Applied the filter queue to the filter state.
   * FilterOptions are either updated in or added to the current filter state.
   * Clears the filter update queue.
   * Commits an update of the filter.
   * Dispatches the fetch members actions to update UI reflecting new filtering.
   *
   * @param context
   * @return void
   */
  applyFilter: async (context: ActionContext<MemberFilterState, RootState>) => {
    // apply changes from the queue
    const filterQueue = context.state.filterUpdateQueue;
    const filterState = context.state.filterState;

    filterQueue.forEach(queueFilterOption => {
      // Find if the option is currently in the filter state and update or add it accordingly.
      const index = filterState ? filterState.findIndex(option => option.id === queueFilterOption.id) : -1;
      if (index < 0) {
        filterState.push(queueFilterOption);
      } else {
        // Check to reassure a nervous TS compiler filterUpdateQueue defined in this case, listed as optional in MemberFilterState.
        if (filterState) {
          filterState[index] = queueFilterOption;
        }
      }
    });
    context.commit('updateFilterUpdateQueue', []);
    await context.commit('updateFilterState', filterState);
    await context.commit('updateFilter');
    // Results in fetching of members.  Go to page 1 in case we are not there already.
    context.dispatch('members/updateMemberPage', 1, {
      root: true,
    });
  },

  /**
   * Clears the stores filterState, update queue and currently selected items to unselect all filters.
   * Results in updating the data table members with no filtering.
   *
   * @param context
   * @return void
   */
  clearFilter: (context: ActionContext<MemberFilterState, RootState>) => {
    // Clear the current filter state
    context.commit('updateFilterState', []);

    // Clear the update queue
    context.commit('updateFilterUpdateQueue', []);

    // Clear currently selected levels.
    context.commit('updateSelectedLevelFilterOptions', []);

    // Clear currently selected ages.
    context.commit('updateSelectedAgeFilterOptions', []);
    context.commit('updateSelectedAgeMin', 0);
    context.commit('updateSelectedAgeMax', 99);

    // Clear currently selected status.
    context.commit('updateSelectedStatusFilterOptions', []);

    // Clear currently selected grouped option.
    context.commit('updateSelectedGroupedFilterOptions', []);

    // Update current filter string
    context.commit('updateFilter');
  },

  /**
   * Clears any unapplied filter selections.
   * Syncs currently selected options with applied filter state.
   * Clears the filter update queue.
   *
   * @param context
   * @return void
   */
  clearFilterUpdateQueue: async (context: ActionContext<MemberFilterState, RootState>) => {
    // There may be level selections that have not been applied.
    await context.dispatch('syncCurrentlySelectedLevels');

    // There may be age range selections that have not been applied.
    await context.dispatch('syncCurrentlySelectedAgeRange');

    // There may be status selections that have not been applied.
    await context.dispatch('syncCurrentlySelectedStatus');

    // There may be a grouped status that have not been applied.
    await context.dispatch('syncCurrentlySelectedGroupedStatus');

    // Clear the update queue
    context.commit('updateFilterUpdateQueue', []);
  },

  /**
   * Syncs the currently selected level filter options with the currently applied filter.
   *
   * @param context
   * @return void
   */
  syncCurrentlySelectedLevels: (context: ActionContext<MemberFilterState, RootState>) => {
    const currentFilter = context.state.filterState;
    const currentLevelFilters = [] as Array<string>;

    currentFilter.forEach(filterOption => {
      if (filterOption.type === 'level' && filterOption.on === true) {
        currentLevelFilters.push(filterOption.id);
      }
    });

    context.commit('updateSelectedLevelFilterOptions', currentLevelFilters);
  },

  /**
   * Syncs currently applied age range filters with the update queue.
   *
   *
   * @param context
   * @return void
   */
  syncCurrentlySelectedAgeRange: (context: ActionContext<MemberFilterState, RootState>) => {
    const currentFilter = context.state.filterState;
    const currentAgeFilters = [] as Array<string>;

    currentFilter.forEach(filterOption => {
      if (filterOption.type === 'age' && filterOption.on === true) {
        currentAgeFilters.push(filterOption.id);
      }
    });

    context.commit('updateSelectedAgeFilterOptions', currentAgeFilters);
  },

  /**
   * Syncs currently applied status filters with the update queue.
   *
   *
   * @param context
   * @return void
   */
  syncCurrentlySelectedStatus: (context: ActionContext<MemberFilterState, RootState>) => {
    const currentFilter = context.state.filterState;
    const currentStatusFilters = [] as Array<string>;

    currentFilter.forEach(filterOption => {
      if (filterOption.type === 'status' && filterOption.on === true) {
        currentStatusFilters.push(filterOption.id);
      }
    });

    context.commit('updateSelectedStatusFilterOptions', currentStatusFilters);
  },

  /**
   * Syncs currently applied grouped status options filters with the update queue.
   *
   *
   * @param context
   * @return void
   */
  syncCurrentlySelectedGroupedStatus: (context: ActionContext<MemberFilterState, RootState>) => {
    const currentFilter = context.state.filterState;
    const currentAgeFilters = [] as Array<string>;

    currentFilter.forEach(filterOption => {
      if (filterOption.type === 'grouped' && filterOption.on === true) {
        currentAgeFilters.push(filterOption.id);
      }
    });
    context.commit('updateSelectedGroupedFilterOptions', currentAgeFilters);
  },
};

/**
 * Available functions for code external to the store to retrieved this modules state properties values.
 * Can alter, calculate with or filter these values before return.
 *
 * @property getters - Object
 */
const getters = {
  numberOfFiltersApplied: (moduleState: MemberFilterState) => moduleState.numberOfFiltersApplied,
  currentFilterString: (moduleState: MemberFilterState) => moduleState.filter,
  selectedLevelFilterOptions: (moduleState: MemberFilterState) => moduleState.selectedLevelFilterOptions,
  selectedAgeFilterOptions: (moduleState: MemberFilterState) => moduleState.selectedAgeFilterOptions,
  selectedAgeMin: (moduleState: MemberFilterState) => moduleState.selectedAgeMin,
  selectedAgeMax: (moduleState: MemberFilterState) => moduleState.selectedAgeMax,
  selectedStatusFilterOptions: (moduleState: MemberFilterState) => moduleState.selectedStatusFilterOptions,
  selectedGroupedFilterOptions: (moduleState: MemberFilterState) => moduleState.selectedGroupedFilterOptions,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
