import { Coach } from '@/models/coach';
import { DataTableOptions } from '@/models/dataTableOptions';
import { makeRequest } from '@/services/api-request';
import { ResponseError } from '@/models/responseError';
import { defineStore } from 'pinia';
import { ErrorIdentity } from '@/models/errorResponseBody';

const defaultSort = 'lastname';
let previousFilterString: string | null = null;

export const useCoachesStore = defineStore('coaches', {
  state: () => ({
    itemsPerPage: 10,
    items: [] as Array<Coach>,
    currentPage: 1,
    total: 0,
    sort: 'lastname',
    sortDirection: 'a',
    filter: '',
    isLoading: true,
    search: '',
    errors: [] as Array<ErrorIdentity>,
  }),
  getters: {
    apiCurrentPage: state => state.currentPage - 1,
    pageCount: state => Math.ceil(state.total / state.itemsPerPage),
  },
  actions: {
    resetState() {
      this.$reset();
    },
    updateSortOptions(dataTableOptions: DataTableOptions) {
      const { sortBy, sortDesc } = dataTableOptions;

      // so api needs either a or d directly in front of the sortfield
      // eg sort=dfirstname,astatus
      if (sortBy.toString().length > 1) {
        this.sort = sortBy.toString();
        // if sortDesc is false then sort ascending
        if (sortDesc.toString() === 'true') {
          this.sortDirection = 'd';
        } else {
          this.sortDirection = 'a';
        }
      } else {
        this.sort = defaultSort;
        this.sortDirection = 'a';
      }
    },
    async fetchCoaches() {
      if ((this.search ?? '') !== previousFilterString) {
        this.currentPage = 1;
      }
      previousFilterString = this.search ?? '';

      const queryParams: Record<string, string> = {
        page: `${this.apiCurrentPage}`,
        per_page: `${this.itemsPerPage}`,
        sort: `${this.sortDirection}${this.sort}`,
      };

      let url = `/users?${new URLSearchParams(queryParams)}&type[]=coach&type[]=admin`;
      if (this.search && this.search.length > 0) {
        url += '&search=' + this.search;
      }
      this.isLoading = true;
      try {
        const resp = await makeRequest('GET', url);
        const totalRecords = resp.response.headers.get('X-Total-Count');
        if (totalRecords) {
          this.total = parseInt(totalRecords, 10);
          this.items = resp.body as Array<Coach>;
        } else {
          this.resetToEmpty();
        }
      } catch (error) {
        this.resetToEmpty();
        if (error instanceof ResponseError) {
          this.errors.push((error as unknown) as ErrorIdentity);
        }
        console.error(error);
      }
      this.isLoading = false;
    },
    resetToEmpty() {
      this.items = [];
      this.currentPage = 1;
      this.total = 0;
      this.isLoading = false;
    },
    updateOptions(dataTableOptions: DataTableOptions) {
      this.currentPage = dataTableOptions.page || 1;
      this.updateSortOptions(dataTableOptions);
      return this.fetchCoaches();
    },
    updateCurrentPage(page: number) {
      this.currentPage = page;
      return this.fetchCoaches();
    },
    updateSearch(search: string) {
      this.search = search;
      return this.fetchCoaches();
    },
    clearErrors() {
      this.errors = [];
    },
  },
});
