import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import { getVariants, replaceVariantTokens, setVariants } from './services/translation';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      const localeMessages = locales(key);
      setVariants(locale, localeMessages);
      messages[locale] = localeMessages;
    }
  });
  return messages;
}

class CustomFormatter {
  private _vue18n;
  private _formatter;

  constructor(options: { vue18n: VueI18n }) {
    this._vue18n = options.vue18n;
    this._formatter = options.vue18n.formatter; // The base formatter
  }

  interpolate(message: string, values: any) {
    const locale = this._vue18n.locale;
    return this._formatter.interpolate(
      replaceVariantTokens(message, getVariants(locale) as Record<string, string>),
      values,
      ''
    );
  }
}

const vue18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});

vue18n.formatter = new CustomFormatter({ vue18n });

export default vue18n;
