import Vue from 'vue';
import { ActionContext } from 'vuex';
import { ContentType, NodeType, SchemeNodeModel } from '@/models/schemeNodeModel';
import { SchemeModel } from '@/models/schemeModel';
import { SchemeState } from '@/models/schemeState';
import { RootState } from '@/models/rootState';
import { AuthResponse } from '@/models/authResponse';
import { Section } from '@/models/section';
import { Video } from '@/models/video';
import { makeRequest } from '@/services/api-request';
import { filterTree, isAssessible, isLevel, slowGetNode, getLookup } from '@/services/utils';
import { videosDb } from '@/services/db/videos';
import { extractVideoIds } from '@/services/scheme';
import { GradeRange } from '@/models/labels';
import { FileModel } from '@/models/fileModel';
import { list } from '@/services/file';

const getDefaultState = (): SchemeState => ({
  schemes: {} as Record<string, SchemeNodeModel>,
  lookups: {} as Record<string, Map<string, SchemeNodeModel>>,
  sections: {} as Record<string, Array<Section>>,
  grades: {} as Record<string, GradeRange>,
  videos: [] as Array<Video>,
  files: [] as Array<FileModel>,
  additionalAssessmentNodeIds: {} as Record<string, Array<string>>, // User added nodes from autocomplete box by scheme
  downloadPercentage: 0,
  schemeList: [] as Array<SchemeModel>,
  publishedSchemes: [] as Array<SchemeModel>,
});

const state = getDefaultState();

const mutations = {
  resetState: (moduleState: SchemeState) => {
    Object.assign(moduleState, getDefaultState());
  },
  updateSchemeList: function(moduleState: SchemeState, schemes: Array<SchemeModel>): void {
    moduleState.schemeList = schemes;
  },
  updatePublishedSchemes: function(moduleState: SchemeState, schemes: Array<SchemeModel>): void {
    moduleState.publishedSchemes = schemes;
  },
  setScheme: function(moduleState: SchemeState, { slug, scheme }: { slug: string; scheme: any }): void {
    moduleState.lookups[slug] = getLookup(scheme);
    Vue.set(moduleState.schemes, slug, scheme);
  },
  updateGrades: function(moduleState: SchemeState, { slug, grades }: { slug: string; grades: GradeRange }): void {
    Vue.set(moduleState.grades, slug, grades || []);
  },
  updateSections: function(
    moduleState: SchemeState,
    { slug, sections }: { slug: string; sections: Array<Section> }
  ): void {
    Vue.set(moduleState.sections, slug, sections || []);
  },
  updateVideos: function(moduleState: SchemeState, videos: Array<Video>): void {
    moduleState.videos = videos || [];
  },
  updateFiles: function(moduleState: SchemeState, files: Array<FileModel>): void {
    moduleState.files = files || [];
  },
  setAdditionalAssessmentNodeIds: (
    moduleState: SchemeState,
    { slug, nodeIds }: { slug: string; nodeIds: Array<string> }
  ) => {
    Vue.set(moduleState.additionalAssessmentNodeIds, slug, nodeIds);
  },
  updateDownloadPercentage: (moduleState: SchemeState, percentage: number) => {
    moduleState.downloadPercentage = percentage;
  },
};

const actions = {
  resetState: async (context: ActionContext<SchemeState, RootState>): Promise<void> => {
    context.commit('resetState');
  },
  getSchemes: async (context: ActionContext<SchemeState, RootState>, queryParams = {} as Record<string, string>) => {
    let url = '/schemes';
    if (Object.entries(queryParams).length) {
      url += `?${new URLSearchParams(queryParams).toString()}`;
    }
    try {
      const res: AuthResponse = await makeRequest('GET', url);
      if (queryParams.published) {
        context.commit('updatePublishedSchemes', res.body);
      } else {
        context.commit('updateSchemeList', res.body);
      }
    } catch (err) {
      console.error(err);
    }
  },

  loadActiveSchemes: async (context: ActionContext<SchemeState, RootState>, force: boolean) => {
    const groupSchemes: Array<string> = context.rootGetters['groups/schemeSlugs'];
    // load all schemes not already loaded that are used by the groups
    await Promise.all(
      groupSchemes.map((slug: string) => {
        if (context.getters.getScheme(slug) && !force) {
          return Promise.resolve(null);
        }
        const loadScheme = async () => {
          const schemeSlug = await context.dispatch('schemes/loadScheme', { raw: false, slug }, { root: true });
          await context.dispatch('schemes/getGrades', schemeSlug, { root: true });
        };
        return loadScheme();
      })
    );
  },

  loadScheme: async (context: ActionContext<SchemeState, RootState>, schemeOptions: { raw: boolean; slug: string }) => {
    let slug = schemeOptions.slug;
    const url = `/curriculum/${schemeOptions.slug}` + (schemeOptions.raw ? '?raw=1' : '');
    try {
      const res: AuthResponse = await makeRequest('GET', url);
      if (res.body.slug) {
        slug = res.body.slug;
        context.commit('setScheme', { slug, scheme: res.body });
      } else {
        throw Error(`[Scheme] - Error fetching scheme from API - ${res.response.statusText}`);
      }
    } catch (err) {
      console.error(err);
    }
    return slug;
  },

  /**
   * Gets the grades for scheme
   *
   * @return {void}
   * @param context: Vuex ActionContext
   */
  getGrades: async (context: ActionContext<SchemeState, RootState>, schemeSlug: string) => {
    const url = `/schemes/${schemeSlug}/grades`;
    const res: AuthResponse = await makeRequest('GET', url);
    context.commit('updateGrades', { slug: schemeSlug, grades: res.body });
  },

  /**
   * Gets the section ids and descriptions for the content types
   *
   * @return {void}
   * @param context: Vuex ActionContext
   */
  getSections: async (context: ActionContext<SchemeState, RootState>, schemeSlug: string) => {
    const url = `/schemes/${schemeSlug}/sections`;
    const res: AuthResponse = await makeRequest('GET', url);
    context.commit('updateSections', { slug: schemeSlug, sections: res.body });
  },

  // Updates a single node
  updateCurriculumNode: async (
    context: ActionContext<SchemeState, RootState>,
    data: { curriculumNode: SchemeNodeModel; schemeSlug: string }
  ) => {
    const url = `/curriculum/${data.schemeSlug}/${data.curriculumNode.id}`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data.curriculumNode),
    };
    try {
      return await makeRequest('GET', url, options);
    } catch (e) {
      console.error('Error updating curriculum node ', e);
    }
  },

  // Updates the whole scheme tree, used when adding new nodes to the tree
  updateCurriculum: async (
    context: ActionContext<SchemeState, RootState>,
    data: { curriculum: SchemeNodeModel; schemeSlug: string }
  ) => {
    const body = JSON.stringify({ curriculum: data.curriculum });
    const authResponse = await makeRequest('POST', `/curriculum/${data.schemeSlug}`, { body });
    context.commit('setScheme', { slug: data.schemeSlug, scheme: authResponse.body }); // raw including content
    return true;
  },

  /**
   * Fetches and caches any images that are needed to render the scheme node.
   *
   * @param context
   * @param {string} schemeNodeId - ID of the scheme node to fetch.
   *
   * using to trigger caching as well as update the page so need to know if it succeeded.
   * @return {boolean}
   */
  saveSchemeNodeInCache: async (
    context: ActionContext<SchemeState, RootState>,
    nodeOptions: { schemeNodeId: string; slug: string }
  ) => {
    // The contents of all nodes is already stored in cache, and this cache is used when rendering the node. We do not
    // need to fetch the node itself therefore, only any files in the content that will need to be displayed.
    const { slug, schemeNodeId } = nodeOptions;
    const node: SchemeNodeModel = context.getters.getNode(slug, schemeNodeId);

    const fileContent = node.content.find(c => c.type === ContentType.Files && c.value.length > 0);
    if (fileContent && Array.isArray(fileContent.value)) {
      const fileLocations = fileContent.value as Array<any>;
      await Promise.all(fileLocations.map(f => (typeof f === 'object' ? fetch(f.location) : null)));
    }
  },

  downloadSchemeVideo: async (context: ActionContext<SchemeState, RootState>, videoId: string) => {
    if ((await videosDb.getVideo(videoId)) !== null) {
      return true; // already have this video
    }
    const { body }: { body: Video } = await makeRequest('GET', `/videos/${videoId}?direct=1`);

    if (body.direct_url) {
      const resp = await fetch(body.direct_url);
      const buffer = await resp.arrayBuffer();
      if (!buffer.byteLength) {
        return false;
      }
      await videosDb.saveVideo(body.id, buffer);
      return true;
    } else {
      return false;
    }
  },

  addVideosToNode: async (
    context: ActionContext<SchemeState, RootState>,
    nodeOptions: { node: SchemeNodeModel; refresh?: boolean }
  ) => {
    const { node } = nodeOptions;
    if (node && 'content' in node && (!node.videos || nodeOptions.refresh)) {
      for (const content of node.content) {
        if (content.type === 'video') {
          const ids = extractVideoIds(content.value as string);
          const resps = await Promise.all(ids.map(id => makeRequest('GET', `/videos/${id}`)));
          Vue.set(
            node,
            'videos',
            resps.map(r => r.body as Video)
          );
          break;
        }
      }
    }
  },

  getVideoUrls: async (context: ActionContext<SchemeState, RootState>, node: SchemeNodeModel) => {
    await context.dispatch('addVideosToNode', { node });
    if (!node.videos) {
      return [];
    }

    const videos = node.videos.map(async video => {
      const buffer = await videosDb.getVideo(video.id); // Check if we have this video saved offline
      return buffer
        ? { id: video.id, url: URL.createObjectURL(new Blob([buffer], { type: 'video/mp4' })), name: video.name }
        : { id: video.id, url: video.url, name: video.name };
    });

    return Promise.all(videos);
  },

  getVideos: async (context: ActionContext<SchemeState, RootState>) => {
    try {
      const authResponse = await makeRequest('GET', '/videos');
      context.commit('updateVideos', authResponse.body);
    } catch (error) {
      console.error('[Error fetching videos]:', error);
    }
  },

  getFiles: async (context: ActionContext<SchemeState, RootState>) => {
    try {
      const files = await list();
      context.commit('updateFiles', files);
    } catch (error) {
      console.error('[Error fetching files]:', error);
    }
  },

  initializeAdditionalAssessmentNodeIds: (context: ActionContext<SchemeState, RootState>) => {
    try {
      const str = localStorage.getItem('additionalAssessmentNodeIds');
      const schemeNodes = str ? JSON.parse(str) : [];
      Object.keys(schemeNodes).forEach(slug => {
        context.commit('setAdditionalAssessmentNodeIds', { slug, nodeIds: schemeNodes[slug] });
      });
    } catch {
      // ignore
    }
  },
  updateAdditionalAssessmentNodeIds: (
    context: ActionContext<SchemeState, RootState>,
    nodeOptions: { slug: string; nodeIds: Array<string> }
  ) => {
    context.commit('setAdditionalAssessmentNodeIds', { slug: nodeOptions.slug, nodeIds: nodeOptions.nodeIds });
    localStorage.setItem('additionalAssessmentNodeIds', JSON.stringify(context.state.additionalAssessmentNodeIds));
  },
  deleteSchemes: async (context: ActionContext<SchemeState, RootState>, schemeSlugs: Array<string>) => {
    for (const schemeSlug of schemeSlugs) {
      const url = `/schemes/${schemeSlug}`;
      await makeRequest('DELETE', url);
    }
  },
  updateScheme: async (context: ActionContext<SchemeState, RootState>, data: { schemeSlug: string; name: string }) => {
    const url = `/schemes/${data.schemeSlug}`;
    await makeRequest('PATCH', url, { body: JSON.stringify({ name: data.name }) });
  },
  undeleteScheme: async (context: ActionContext<SchemeState, RootState>, schemeSlug: string) => {
    const url = `/schemes/${schemeSlug}/undelete`;
    await makeRequest('POST', url);
  },
  rehomeLinkedNode: async (
    context: ActionContext<SchemeState, RootState>,
    data: { scheme_uuid: string; node_id: string }
  ) => {
    const url = '/curriculum/rehome-linked-node';
    const options = { body: JSON.stringify(data) };
    try {
      await makeRequest('POST', url, options);
      await context.dispatch('loadScheme', { raw: true, slug: data.scheme_uuid });
      return true;
    } catch (e) {
      console.error('Error rehoming curriculum node ', e);
      return false;
    }
  },
  mirrorNode: async (
    context: ActionContext<SchemeState, RootState>,
    data: {
      link_scheme_uuid: string;
      owner_scheme_uuid: string;
      link_node_id: string;
      owner_node_id: string;
    }
  ) => {
    const url = '/curriculum/mirror';
    const options = { body: JSON.stringify(data) };
    try {
      await makeRequest('POST', url, options);
      await context.dispatch('loadScheme', { raw: true, slug: data.link_scheme_uuid });
      return true;
    } catch (e) {
      console.error('Error mirroring scheme', e);
      return e;
    }
  },
};

const getters = {
  schemeList: (moduleState: SchemeState) => moduleState.schemeList,
  publishedSchemes: (moduleState: SchemeState) => moduleState.publishedSchemes,
  grades: (moduleState: SchemeState) => (slug: string) => moduleState.grades[slug] || null,
  sections: (moduleState: SchemeState) => (slug: string) => moduleState.sections[slug] || null,
  videos: (moduleState: SchemeState) => moduleState.videos,
  files: (moduleState: SchemeState) => moduleState.files,
  additionalAssessmentNodeIds: (moduleState: SchemeState) => (slug: string) =>
    moduleState.additionalAssessmentNodeIds[slug] || [],
  downloadPercentage: (moduleState: SchemeState) => moduleState.downloadPercentage,
  schemeAssessableNodesOnly: (moduleState: SchemeState, getters: any) => (slug: string): SchemeNodeModel => {
    const tree = getters.getScheme(slug);
    return filterTree(tree, isAssessible);
  },
  schemeAssessableLevelsOnly: (moduleState: SchemeState, getters: any) => (slug: string): SchemeNodeModel => {
    return filterTree(getters.schemeAssessableNodesOnly(slug), isLevel);
  },

  getScheme: (moduleState: SchemeState) => (slug: string): SchemeNodeModel => {
    return moduleState.schemes[slug] || null;
  },
  /**
   * Returns the node without content from the stores cached scheme tree.
   *
   * @param {Object} moduleState - Stores state object, passed automatically.
   * @return {SchemeNodeModel}
   */
  getNode: (moduleState: SchemeState) => (schemeSlug: string, schemeNodeId: string): SchemeNodeModel => {
    if (!(schemeSlug in moduleState.schemes)) {
      return {} as SchemeNodeModel; // scheme not loaded yet
    }
    if (!(schemeSlug in moduleState.lookups)) {
      console.error(`Did not use lookup to fetch node ${schemeNodeId}`);
      return slowGetNode(moduleState.schemes[schemeSlug], schemeNodeId);
    }

    if (!moduleState.lookups[schemeSlug].has(schemeNodeId)) {
      console.error(`Node missing from lookup: ${schemeNodeId}`);
    }
    return moduleState.lookups[schemeSlug].get(schemeNodeId) || ({} as SchemeNodeModel);
  },
  getSchemeLookup: (moduleState: SchemeState) => (schemeSlug: string): Map<string, SchemeNodeModel> => {
    if (!(schemeSlug in moduleState.lookups)) {
      console.error(`Scheme lookup missing: ${schemeSlug}`);
      return new Map();
    }
    return moduleState.lookups[schemeSlug];
  },
  schemes: (moduleState: SchemeState): Array<SchemeModel> =>
    Object.keys(moduleState.schemes).map(slug => ({ slug, name: moduleState.schemes[slug].title })),

  defaultSchemeSlug: (moduleState: SchemeState) => {
    const slugs = Object.keys(moduleState.schemes);
    const foundDefault = slugs.find(slug => moduleState.schemes[slug].title === 'default');
    return foundDefault || slugs[0];
  },
  getSlugFromUuid: (moduleState: SchemeState) => (uuid: string): string | null => {
    const scheme = moduleState.schemeList.find(scheme => scheme.uuid === uuid);
    return scheme ? scheme.slug : null;
  },
  getNodeAndParents: (moduleState: SchemeState, getters: any) => (
    slug: string,
    nodeId: string
  ): Array<SchemeNodeModel> => {
    const nodes: Array<SchemeNodeModel> = [];
    let node = getters.getNode(slug, nodeId);
    nodes.unshift(node);
    while (true) {
      if (!node.parentId || node.parentId === '/') {
        break;
      }
      node = getters.getNode(slug, node.parentId) as SchemeNodeModel;
      nodes.unshift(node);
    }
    return nodes;
  },
  getNodeName: (moduleState: SchemeState, getters: any) => (slug: string, nodeId: string): string => {
    const nodes: Array<SchemeNodeModel> = getters.getNodeAndParents(slug, nodeId);
    return nodes.map(node => node.title).join(' > ');
  },
  getNodeType: (moduleState: SchemeState, getters: any) => (slug: string, nodeId: string): NodeType | null => {
    const node = getters.getNode(slug, nodeId) as SchemeNodeModel;
    return node.t ? (node.t as NodeType) : null;
  },

  getParentNodeIdsWithTypes: (moduleState: SchemeState, getters: any) => (
    slug: string,
    nodeId: string
  ): Array<[string, NodeType]> => {
    const parentNodeIds: Array<[string, NodeType]> = [];
    let node = getters.getNode(slug, nodeId);
    while (true) {
      if (!('parentId' in node) || node.parentId === '/') {
        break;
      }
      node = getters.getNode(slug, node.parentId) as SchemeNodeModel;
      parentNodeIds.push([node.id, node.t]);
    }
    return parentNodeIds;
  },

  getParentNodeIds: (moduleState: SchemeState, getters: any) => (slug: string, nodeId: string): Array<string> => {
    return getters.getParentNodeIdsWithTypes(slug, nodeId).map(([nodeId]: [string]) => nodeId);
  },

  getParentLevelIds: (moduleState: SchemeState, getters: any) => (slug: string, nodeId: string): Array<string> => {
    return getters
      .getParentNodeIdsWithTypes(slug, nodeId)
      .flatMap(([nodeId, type]: [string, NodeType]) => (type === NodeType.Level ? [nodeId] : []));
  },

  getChildrenNodeIds: (moduleState: SchemeState, getters: any) => (slug: string, nodeId: string): Array<string> => {
    const node: SchemeNodeModel = getters.getNode(slug, nodeId);
    return node.children.map(c => c.id);
  },

  getSiblingNodeIds: (moduleState: SchemeState, getters: any) => (slug: string, nodeId: string): Array<string> => {
    const node: SchemeNodeModel = getters.getNode(slug, nodeId);
    const parentNode: SchemeNodeModel = getters.getNode(slug, node.parentId);
    return parentNode.children.map(c => c.id);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
