import VueI18n, { LocaleMessageObject } from 'vue-i18n';
import _merge from 'lodash/merge';
import _isString from 'lodash/isString';
import LocaleMessages = VueI18n.LocaleMessages;

function capitalize(str: string) {
  if (!str) return str;

  const firstChar = str.charAt(0);
  if (firstChar.match(/[a-zA-Z]/)) {
    return firstChar.toUpperCase() + str.slice(1);
  }

  // For non-Latin scripts (e.g., Chinese), return the string as is
  return str;
}

function replaceVariantTokens(val: string, variants: Record<string, string>) {
  for (const token in variants) {
    const pos = val.indexOf(token);
    if (pos !== -1 && _isString(variants[token])) {
      val = val.replace(token, pos === 0 ? capitalize(variants[token]) : variants[token]);
    }
  }
  return val;
}

const variants: Record<string, LocaleMessageObject> = {};

function setVariants(locale: string, messages: LocaleMessageObject) {
  // override variants with portal-specific versions if they exist
  const portal = process.env.VUE_APP_BUILD_TARGET as string;
  let newVariants = (messages.variants ?? {}) as LocaleMessageObject;
  newVariants = Object.assign(
    variants[locale] ?? {},
    newVariants,
    (portal as string) in newVariants ? newVariants[portal] : {}
  );

  variants[locale] = newVariants;
}

function getVariants(locale: string) {
  return variants[locale];
}

/**
 * Gets a file if it exists to override default translations
 *
 * @param locale
 */
const getTranslationOverrides = async (
  locale: string | undefined,
  translationUrl: string
): Promise<LocaleMessageObject> => {
  if (!locale) {
    locale = process.env.VUE_APP_I18N_LOCALE || 'en';
  }
  if (!translationUrl) {
    return Promise.resolve({});
  }

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  headers.append('Origin', window.location.hostname);

  // Remove trailing slash
  let url = translationUrl;
  if (url.charAt(url.length - 1) === '/') {
    url = url.substring(0, url.lastIndexOf('/'));
  }

  const response = await fetch(`${url}/${locale}.json`, {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    const message = `Error fetching ${url}: ${response.statusText} - if no file expected then that is OK`;
    console.log(message);
    return Promise.resolve({}); // if no file found then its OK app will run without additional translations
  } else {
    return (await response.json()) as LocaleMessageObject;
  }
};

/**
 * Provided with existing localeMessages for selected locale
 * and combines them with only the ones that need overriding
 *
 * @param locale
 * @param localeMessages
 * @return Promise<LocaleMessages> combined localeMessages
 */
const overrideDefaultTranslations = async (
  locale: string,
  localeMessages: LocaleMessages,
  translationUrl: string
): Promise<LocaleMessageObject> => {
  let overrides = {} as LocaleMessageObject;
  try {
    overrides = await getTranslationOverrides(locale, translationUrl); // returns an empty object if there's not one
    setVariants(locale, overrides);
  } catch (error) {
    console.warn(
      'Error fetching translation is only a problem if expecting an additional override remote en.json file'
    );
  }
  const existing = localeMessages[locale];
  return _merge(existing, overrides); // overrides will be empty object if fetch fails above
};

export { getTranslationOverrides, overrideDefaultTranslations, setVariants, getVariants, replaceVariantTokens };
