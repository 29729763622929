import { ErrorIdentity } from '@/models/errorResponseBody';
import { User } from '@/models/user';
import { ListState } from '@/models/listState';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { AuthResponse } from '@/models/authResponse';
import { makeRequest } from '@/services/api-request';
import { getActions, getGetters, getMutations } from '../list-state-mixin';
import { AppIds } from '@/consts';
import { ResponseError } from '@/models/responseError';

/**
 * List state values for the users related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - ListState
 */
const getDefaultState = (): ListState => ({
  itemsPerPage: 10,
  items: [] as Array<User>,
  currentPage: 1,
  total: 0,
  defaultSort: 'lastname',
  sort: 'lastname',
  sortDirection: 'a',
  isLoading: true,
  selected: [], // Selected users.
  search: '',
  errors: [] as Array<ErrorIdentity>,
});

const state = getDefaultState();
let previousFilterString: string | null = null;

const actions = getActions();
const mutations = getMutations();
const getters = getGetters();

mutations.resetState = (moduleState: ListState) => {
  Object.assign(moduleState, getDefaultState());
};

/**
 * Fetches users with current page, sort and sort order included.
 *
 * @param {ActionContext} context
 * @return void
 */
actions.fetchItems = async (context: ActionContext<ListState, RootState>) => {
  const apiCurrentPage: number = context.state.currentPage - 1;
  const queryParams: Record<string, string> = {
    per_page: `${context.state.itemsPerPage}`,
    page: `${apiCurrentPage}`,
    sort: `${context.state.sortDirection}${context.state.sort}`,
    with_clubs: '1',
  };
  if (context.state.search && context.state.search.length > 0) {
    queryParams.search = context.state.search;
  }
  if ((context.state.search ?? '') !== previousFilterString) {
    queryParams.page = '0';
    context.state.currentPage = 1;
  }
  previousFilterString = context.state.search ?? '';
  const searchParams = new URLSearchParams(queryParams);

  const appId = context.rootGetters.appId as AppIds;
  const userTypes = appId === 'scheme' ? ['ngo', 'admin', 'coach', 'api'] : ['admin', 'coach'];
  for (const type of userTypes) {
    searchParams.append('type[]', type);
  }

  const url = `/users?${searchParams}`;
  try {
    context.commit('updateLoading', true);
    const authResponse: AuthResponse = await makeRequest('GET', url);
    const totalRecords = authResponse.response.headers.get('X-Total-Count');
    if (totalRecords) {
      context.commit('updateItems', authResponse.body as Array<User>);
      context.commit('updateTotal', parseInt(totalRecords, 10));
      context.commit('updateLoading', false);
    } else {
      context.dispatch('resetToEmpty');
    }
  } catch (error) {
    if (error instanceof ResponseError) {
      context.commit('updateErrors', error.body);
    }
    context.dispatch('resetToEmpty');
    console.error(error);
  }
};

actions.createItem = async (context: ActionContext<ListState, RootState>, user: User) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = Object.assign({}, user);
  delete params.uuid;
  await makeRequest('POST', '/users', { body: JSON.stringify(params) });
};

actions.updateItem = async (context: ActionContext<ListState, RootState>, user: User) => {
  const url = `/users/${user.uuid}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = Object.assign({}, user);
  delete params.uuid;
  delete params.auth_status;
  delete params.ngo_id;
  await makeRequest('PATCH', url, { body: JSON.stringify(params) });
};

actions.deleteItems = async (context: ActionContext<ListState, RootState>, data: Array<User>) => {
  await Promise.all(
    data.map(async (user: User) => {
      const url = `/users/${user.uuid}`;
      return makeRequest('DELETE', url);
    })
  );
};

actions.updatePassword = async (
  context: ActionContext<ListState, RootState>,
  { user, password }: { user: User; password: string }
) => {
  const url = `/users/${user.uuid}/password`;
  await makeRequest('PATCH', url, { body: JSON.stringify({ password }) });
};

actions.updateUnsubscribes = async (
  context: ActionContext<ListState, RootState>,
  { receiveEmails, uuid, token }: { receiveEmails: boolean; uuid?: string; token?: string }
): Promise<string> => {
  if (!uuid) {
    uuid = context.rootGetters['auth/loggedInUserId'];
  }
  try {
    const unsubOperational = receiveEmails ? 0 : 1;
    const body = JSON.stringify({ unsubscribe_operational: unsubOperational });
    let resp: AuthResponse;
    if (token) {
      resp = await makeRequest(
        'PATCH',
        `/users/${uuid}/unsubscribes`,
        {
          headers: { Authorization: `Bearer ${token}` },
          body,
        },
        false
      );
    } else {
      resp = await makeRequest('PATCH', `/users/${uuid}/unsubscribes`, { body });
    }
    if (resp.response.ok) {
      return 'ok';
    }
  } catch (e) {
    console.error(e);
  }
  return 'unknown';
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
