import { DataTableOptions } from '@/models/dataTableOptions';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { Member, Score, Scores, Assessments, BadgeAward, Progress, Awards, Award } from '@/models/member';
import { makeRequest } from '@/services/api-request';
import { ErrorIdentity } from '@/models/errorResponseBody';
import { RegisterEntry } from '@/models/registerEntry';
import moment from 'moment';
import { membersDb } from '@/services/db/members';
import { AssessmentDetail } from '@/models/assessmentDetail';
import { nodeRequirementsMet } from '@/services/utils';
import { Group } from '@/models/group';
import Vue from 'vue';
import _remove from 'lodash/remove';
import { NodeType, SchemeNodeModel } from '@/models/schemeNodeModel';
import { Mutex } from 'async-mutex';
import { GroupMemberState } from '@/models/groupMemberState';

/**
 * List state values for the group members related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - GroupMemberState
 */
const getDefaultState = (): GroupMemberState => ({
  itemsPerPage: 10,
  items: [] as Array<Member>,
  currentPage: 1,
  total: 0,
  defaultSort: 'firstname',
  sort: 'firstname',
  sortDirection: 'a',
  isLoading: false,
  selected: [],
  search: '',
  currentGroupId: 0,
  memberLegendCache: {} as Record<string, any>,
});

const state = getDefaultState();
const mutex = new Mutex();
let previousFilterString: string | null = null;

/**
 * Vuex store mutations available for updating state values above.
 * These should be the only way state values are updated.
 * Should be synchronous transactions only to ensure predictability of state.
 *
 * @property mutations - Object
 */
const mutations = {
  resetState: (moduleState: GroupMemberState) => {
    Object.assign(moduleState, getDefaultState());
  },
  updateGroupMembers: (
    moduleState: GroupMemberState,
    { groupId, members, total }: { groupId: number; members: Array<Member>; total: number }
  ) => {
    moduleState.items = members || [];
    moduleState.total = total;
    moduleState.currentGroupId = groupId;
  },

  updateGroupMember: (
    moduleState: GroupMemberState,
    { memberUuid, groupId, member }: { memberUuid: string; groupId: number; member: Member }
  ) => {
    const members = moduleState.items as Array<Member>;
    const i = members.findIndex((member: Member) => member.group_id === groupId && member.uuid === memberUuid);
    if (i > -1) {
      Vue.set(moduleState.items, i, member);
    }
  },

  /**
   * Updates the number of items per page
   *
   * @param {GroupMemberState} moduleState
   * @param {number} noOfItems
   */
  updateItemsPerPage: (moduleState: GroupMemberState, noOfItems: number) => {
    moduleState.itemsPerPage = noOfItems;
  },

  /**
   * Updates the current page number for the paginator
   *
   * @param {GroupMemberState} moduleState
   * @param {number} currentPage
   */
  updateCurrentPage: (moduleState: GroupMemberState, currentPage: number) => {
    moduleState.currentPage = currentPage;
  },

  clearSelectedGroupMembers: (moduleState: GroupMemberState) => {
    moduleState.selected = [];
  },

  updateSelectedGroupMembers: (moduleState: GroupMemberState, selectedMembers: Array<Member>) => {
    moduleState.selected = selectedMembers;
  },

  updateSortOptions: (moduleState: GroupMemberState, dataTableOptions: DataTableOptions) => {
    const { sortBy, sortDesc } = dataTableOptions;

    let sortByStr = sortBy.toString();

    // We need to sort on dob if age column is selected.
    if (sortByStr === 'age') {
      sortByStr = 'dob';
    }

    // so api needs either a or d directly in front of the sortfield
    // eg sort=dfirstname,astatus
    if (sortByStr.length > 1) {
      moduleState.sort = sortByStr;
      // if sortDesc is false then sort ascending
      if (sortDesc.toString() === 'true') {
        moduleState.sortDirection = 'd';
      } else {
        moduleState.sortDirection = 'a';
      }
    } else {
      moduleState.sort = moduleState.defaultSort;
      moduleState.sortDirection = 'a';
    }
  },

  updateSort: (moduleState: GroupMemberState, sort: string): void => {
    moduleState.sort = sort;
  },

  /**
   * Update sortDirection - a or d
   *
   * @param {GroupMemberState} moduleState
   * @param {string} sortDirection
   */
  updateSortDirection: (moduleState: GroupMemberState, sortDirection: string): void => {
    moduleState.sortDirection = sortDirection;
  },

  updateLoading: (moduleState: GroupMemberState, isLoading: boolean) => {
    moduleState.isLoading = isLoading;
  },
  updateSearch: (moduleState: GroupMemberState, searchStr: string) => {
    moduleState.search = searchStr.replace(/[|&;$%@"<>()+,]/g, '');
  },

  updateErrors: (moduleState: GroupMemberState, errorDetail: ErrorIdentity) => {
    if (moduleState.errors) {
      moduleState.errors.push(errorDetail);
    }
  },

  clearErrors: (moduleState: GroupMemberState) => {
    moduleState.errors = [];
  },
  updateMemberLegendCache: (
    moduleState: GroupMemberState,
    { memberUuid, legend, dob }: { memberUuid: string; legend: string; dob: string }
  ) => {
    moduleState.memberLegendCache[memberUuid] = { legend, dob };
  },
  clearMemberLegendCache: (moduleState: GroupMemberState) => {
    moduleState.memberLegendCache = {};
  },
};

/**
 * Available functions for handling business logic relating to this store modules state properties.
 * Can be asynchronous.
 * Interacts with the modules state properties via committing one or more synchronous mutations.
 *
 * @property actions - Object
 */
const actions = {
  resetState: async (context: ActionContext<GroupMemberState, RootState>): Promise<void> => {
    context.commit('resetState');
  },

  /**
   * Fetches members from api possibly filtered by page and search
   * and updates the store items state for the UI
   * does not work offline and should not be used by coach app
   *
   * @param {ActionContext} context
   * @param {string} groupId
   */
  fetchGroupMembers: async (context: ActionContext<GroupMemberState, RootState>, groupId: string) => {
    const apiCurrentPage: number = context.state.currentPage - 1;
    const withProgress = context.rootGetters['auth/isOrganisationPortal'] ? '0' : '1';
    const queryParams: Record<string, string> = {
      with_progress: withProgress,
      page: `${apiCurrentPage}`,
      per_page: `${context.state.itemsPerPage}`,
      sort: `${context.state.sortDirection}${context.state.sort}`,
    };
    if (context.state.search && context.state.search.length > 0) {
      queryParams.search = context.state.search;
    }
    if ((context.state.search ?? '') !== previousFilterString) {
      queryParams.page = '0';
      context.state.currentPage = 1;
    }
    previousFilterString = context.state.search ?? '';
    const url = `/groups/${groupId}/members?${new URLSearchParams(queryParams)}`;

    try {
      context.commit('updateLoading', true);
      const authResponse = await makeRequest('GET', url);
      const totalRecords = authResponse.response.headers.get('X-Total-Count');
      if (totalRecords) {
        context.commit('updateGroupMembers', {
          groupId,
          members: authResponse.body as Array<Member>,
          total: totalRecords,
        });
      } else {
        context.commit('updateGroupMembers', { groupId, members: [], total: 0 });
      }
    } catch (error) {
      console.error('[Error] :', error);
    } finally {
      context.commit('updateLoading', false);
    }
  },

  updateOptions: (
    context: ActionContext<GroupMemberState, RootState>,
    data: {
      dataTableOptions: DataTableOptions;
      groupId: number;
    }
  ) => {
    context.commit('updateCurrentPage', data.dataTableOptions.page);
    context.commit('updateSortOptions', data.dataTableOptions);
    context.dispatch('fetchGroupMembers', data.groupId);
  },
  updateGroupMembersPage: (
    context: ActionContext<GroupMemberState, RootState>,
    data: {
      page: number;
      groupId: number;
    }
  ) => {
    context.commit('updateCurrentPage', data.page);
    context.dispatch('fetchGroupMembers', data.groupId);
  },

  /**
   * Marks an individual group members as present for the given day.
   *
   *
   * @param {ActionContext<GroupMemberState, RootState>} context
   * @param {Member} member
   * @return {Promise<void>>}
   */
  markMemberRegister: async (
    context: ActionContext<GroupMemberState, RootState>,
    { member, group, date }: { member: Member; group: Group; date: string }
  ) => {
    // deliberately setting the timestamp and date here before passing to the function
    // so they all have the same
    const registerEntry: RegisterEntry = {
      member: member,
      group_ngo_id: group.ngo_id || null,
      registerDate: date,
      timestamp: moment().format('X'),
    };
    try {
      await mutex.runExclusive(async () => {
        await context.dispatch('markMemberRegisterInStoreDb', registerEntry);
      });
    } catch (err) {
      console.error('Error marking member register in the store DB: ', err);
    }
    try {
      await context.dispatch('markMemberRegisterInApi', registerEntry);
    } catch (err) {
      console.error('Error marking member register in store db: ', err);
    }
  },

  /**
   * Awards a badge to a member
   */
  awardBadge: async (
    context: ActionContext<GroupMemberState, RootState>,
    { member, group, badgeId }: { member: Member; group: Group; badgeId: number }
  ) => {
    const award: BadgeAward = {
      badge_id: badgeId,
      achieve_date: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    try {
      const awards = [...(member.badges || []), award];
      await membersDb.setBadges(member.uuid, awards);
      await context.dispatch('refreshMemberFromDb', { groupId: group.id, memberUuid: member.uuid });
    } catch (err) {
      console.error('Failed to award badge in DB', err);
      return err;
    }

    try {
      const data = { award, group_ngo_id: group.id };
      await makeRequest('POST', `/members/${member.uuid}/badges`, { body: JSON.stringify(data) });
    } catch (err) {
      // may be offline
    }
    return true;
  },

  /**
   * Remove a badge from a member
   */
  removeBadge: async (
    context: ActionContext<GroupMemberState, RootState>,
    { member, group, badgeId }: { member: Member; group: Group; badgeId: number }
  ) => {
    _remove(member.badges || [], badge => badge.badge_id === badgeId);
    try {
      await membersDb.setBadges(member.uuid, member.badges || []);
      await context.dispatch('refreshMemberFromDb', { groupId: group.id, memberUuid: member.uuid });
    } catch (err) {
      console.error('Failed to remove badge', err);
      return err;
    }

    try {
      await makeRequest('DELETE', `/members/${member.uuid}/badges/${badgeId}`);
    } catch (err) {
      // may be offline
    }
    return true;
  },

  markForMove: async (
    context: ActionContext<GroupMemberState, RootState>,
    {
      member,
      groupId,
      remoteNodeId,
      stay = false,
    }: {
      member: Member;
      groupId: number;
      remoteNodeId: string;
      stay: boolean;
    }
  ) => {
    try {
      // Due to a bug in Dexie the members scores get deleted if we don't set them again here
      await membersDb.markForMove(member.uuid, groupId, remoteNodeId, member.scores);
      await context.dispatch('refreshMemberFromDb', { groupId, memberUuid: member.uuid });
    } catch (err) {
      console.error('Failed to mark for move', err);
      return err;
    }

    try {
      const data = { remote_node_id: remoteNodeId, stay: stay };
      await makeRequest('POST', `/groups/${groupId}/members/${member.uuid}/mark`, { body: JSON.stringify(data) });
    } catch (err) {
      // may be offline
    }
    return true;
  },

  cancelMarkForMove: async (
    context: ActionContext<GroupMemberState, RootState>,
    { member, groupId }: { member: Member; groupId: number }
  ) => {
    try {
      // Due to a bug in Dexie the members scores get deleted if we don't set them again here
      await membersDb.markForMove(member.uuid, groupId, null, member.scores);
      await context.dispatch('refreshMemberFromDb', { groupId, memberUuid: member.uuid });
    } catch (err) {
      console.error('Failed to cancel mark for move', err);
      return err;
    }

    try {
      await makeRequest('DELETE', `/groups/${groupId}/members/${member.uuid}/mark`);
    } catch (err) {
      //
    }
    return true;
  },

  /**
   * Marks an individual group members as present for the given day.
   *
   *
   * @param {ActionContext<GroupMemberState, RootState>} context
   * @param {RegisterEntry} registerEntry
   * @return {Promise<void>>}
   */
  markMemberRegisterInApi: async (
    context: ActionContext<GroupMemberState, RootState>,
    registerEntry: RegisterEntry
  ): Promise<void> => {
    let method: 'POST' | 'DELETE' = 'POST';
    let url = `/members/${registerEntry.member.uuid}/attendance`;
    let data = { ts: registerEntry.timestamp };
    if (registerEntry.group_ngo_id && registerEntry.member.class_member_id) {
      data = Object.assign(data, {
        group_ngo_id: registerEntry.group_ngo_id,
        class_member_id: registerEntry.member.class_member_id,
      });
    }

    if (!registerEntry.member.in) {
      method = 'DELETE';
      url = `${url}/${registerEntry.registerDate}`;
    } else {
      data = Object.assign(data, { date: registerEntry.registerDate });
    }
    try {
      await makeRequest(method, url, { body: JSON.stringify(data) });
    } catch (err) {
      console.error('[Attendance] - Could not mark members attendance', err);
    }
  },

  /**
   * Marks an individual group members as present for the given day.
   *
   *
   * @param {ActionContext<GroupMemberState, RootState>} context
   * @param {RegisterEntry} registerEntry
   * @return {Promise<void>>}
   */
  markMemberRegisterInStoreDb: async (
    context: ActionContext<GroupMemberState, RootState>,
    registerEntry: RegisterEntry
  ): Promise<void> => {
    return membersDb.markMemberRegister(registerEntry);
  },

  /**
   * Gets the members of the group by id
   *
   * @param {ActionContext} context
   * @param {string} groupId
   */
  fetchMembersByGroupId: async (
    context: ActionContext<GroupMemberState, RootState>,
    groupId: number
  ): Promise<void> => {
    try {
      context.dispatch('clearMemberLegendCache');
      if (context.state.currentGroupId === groupId) {
        return; // already loaded
      }
      context.commit('updateLoading', true);
      const members: Array<Member> = await context.dispatch('fetchMembersFromDbStoreByGroupId', groupId);
      context.commit('updateGroupMembers', { groupId, members, total: members.length });
    } catch (e) {
      console.error('Error loading group members', e);
    } finally {
      context.commit('updateLoading', false);
    }
  },

  /**
   * Refetch a single member from the DB after it has been updated
   *
   * @param {ActionContext} context
   * @param {string} groupId
   */
  refreshMemberFromDb: async (
    context: ActionContext<GroupMemberState, RootState>,
    { groupId, memberUuid }: { groupId: number; memberUuid: string }
  ): Promise<void> => {
    const member = await membersDb.getMemberByUuid(groupId, memberUuid);
    context.commit('updateGroupMember', { memberUuid, groupId, member });
  },

  /**
   * Fetches the groups members from the API
   *
   * @param {ActionContext<GroupMemberState, RootState>} context
   * @param {string} groupId
   */
  fetchMembersFromApiByGroupId: async (
    context: ActionContext<GroupMemberState, RootState>,
    group: Group
  ): Promise<Array<Member>> => {
    const queryParams: Record<string, string> = {
      with_progress: '1',
      with_attendance: '1',
      with_alerts: '1',
      with_awards: '1',
      per_page: '1000',
    };
    if (group.ngo_id) {
      queryParams.group_ngo_id = `${group.ngo_id}`;
    }
    if (group.scheme_slug) {
      queryParams.scheme_uuid = group.scheme_slug;
    }
    const url = `/groups/${group.id}/members?${new URLSearchParams(queryParams)}`;

    try {
      const authResponse = await makeRequest('GET', url);
      return authResponse.body as Array<Member>;
    } catch (e) {
      throw Error('[Groups] - Error fetching groups from network - ' + e);
    }
  },

  /**
   * Fetches the groups members from the DbStore
   *
   * @param {ActionContext<GroupMemberState, RootState>} context
   * @param {string} groupId
   */
  fetchMembersFromDbStoreByGroupId: async (
    context: ActionContext<GroupMemberState, RootState>,
    groupId: number
  ): Promise<Array<Member> | undefined> => {
    return membersDb.getMembersByGroupId(groupId);
  },

  /**
   * If a change to this component instances skill node this will fire off the new selection to the API,
   * in future will use DB if offline.
   *
   * @return {void}
   * @param {ActionContext} context
   * @param {AssessmentDetail} assessmentDetail
   */
  updateAssessmentInApi: async (
    context: ActionContext<GroupMemberState, RootState>,
    assessmentDetail: AssessmentDetail
  ) => {
    try {
      const url = `/members/${assessmentDetail.memberUuid}/assessments/${assessmentDetail.schemeSlug}`;
      const body = JSON.stringify({
        member_id: assessmentDetail.memberUuid,
        node_id: assessmentDetail.schemeNodeId,
        assessment: assessmentDetail.assessmentGrade,
        ts: assessmentDetail.timestamp,
        context: {
          group_ngo_id: assessmentDetail.groupNgoId,
        },
      });
      await makeRequest('POST', url, { body });
    } catch (error) {
      console.error('[Assessment] - Could not send members assessment', error);
    }
  },

  applyAssessmentToMember: (
    context: ActionContext<GroupMemberState, RootState>,
    { assessmentDetail, member }: { assessmentDetail: AssessmentDetail; member: Member }
  ): [Scores, Assessments, Progress | undefined, Awards] => {
    const memberAssessments: Assessments = member.assessments;
    const memberProgress = member.progress;
    const newAwards: Awards = member.awards || {};
    const newScores: Scores = member.scores;
    const newScore: Score = {} as Score;

    newScore.assessment = String(assessmentDetail.assessmentGrade);
    newScore.ts = assessmentDetail.timestamp;
    newScore.user_uuid = context.rootGetters['auth/loggedInUserId'];
    newScores[assessmentDetail.schemeNodeId] = newScore;

    if (memberAssessments) {
      // Assessed nodes are always available, up to the nearest level (same behaviour as API).
      [
        [assessmentDetail.schemeNodeId, ''], // the current node
        ...context.rootGetters['schemes/getParentNodeIdsWithTypes'](
          assessmentDetail.schemeSlug,
          assessmentDetail.schemeNodeId
        ), // and its parents
      ].some(([nodeId, type]) => {
        memberAssessments.available[nodeId] = true;
        const isLevel = type === NodeType.Level;
        if (isLevel && memberProgress && !(nodeId in memberProgress)) {
          // also mark this level as in-progress
          memberProgress[nodeId] = [0, 0];
        }
        return isLevel; // stop looping after marking the first level as available
      });

      // Check if they've completed the required amount to pass this level
      let passed = false;
      if (assessmentDetail.assessmentGrade >= assessmentDetail.assessmentPassGrade) {
        passed = true;
      }

      if (passed) {
        memberAssessments.complete[assessmentDetail.schemeNodeId] = true;
      } else {
        delete memberAssessments.complete[assessmentDetail.schemeNodeId];
      }

      // Check if any parents are now complete, also
      const parentNodeIds: Array<string> = context.rootGetters['schemes/getParentNodeIds'](
        assessmentDetail.schemeSlug,
        assessmentDetail.schemeNodeId
      );

      const levelsToCheckSiblingProgress = [];

      // When a local assessment is made, we update completion and progress enough that the current/passed levels is
      // correct, but we do not attempt to set the available nodes tree-wide. This would be done by the backend next
      // time the system is synced.
      for (const parentNodeId of parentNodeIds) {
        const parentNode = context.rootGetters['schemes/getNode'](
          assessmentDetail.schemeSlug,
          parentNodeId
        ) as SchemeNodeModel;
        if (nodeRequirementsMet(parentNode.completion, memberAssessments.complete)) {
          memberAssessments.complete[parentNodeId] = true;
          // no longer in progress, move to 'passed levels'
          if (memberProgress) {
            delete memberProgress[parentNodeId];
          }

          // if this is a level, we also add an award
          if (parentNode.t === NodeType.Level && newAwards && !(parentNodeId in newAwards)) {
            // give a temporary award object until we next sync the system, so we can show level completion time.
            const now = moment().unix();
            const total = parentNode.completion ? parentNode.completion[1] : 1;
            const award: Award = {
              slug: assessmentDetail.schemeSlug,
              started_ts: now,
              completed_ts: now,
              completed: total,
              total,
              created_at: moment().format('YYYY-MM-DD HH:mm'),
            };
            newAwards[parentNodeId] = award;

            // Since we completed this level, we might need to change the progress of our sibling levels
            levelsToCheckSiblingProgress.push(parentNodeId);
          }
        } else {
          delete memberAssessments.complete[parentNodeId];
        }
      }

      // NOTE: At this point we might as well just reimplement bgapi's applyAssessments fully. Will mean including the
      // hidden nodes in the frontend curriculum and doing a full scan and updating completed/available/progress fully.
      // For now, doing a best-effort update of the progress/available, which will be 'fixed' at next-sync.

      // Update progress of any sibling-levels
      const siblings = levelsToCheckSiblingProgress
        .map(siblingNodeId =>
          context.rootGetters['schemes/getSiblingNodeIds'](assessmentDetail.schemeSlug, siblingNodeId).filter(
            (nodeId: string) => nodeId !== siblingNodeId
          )
        )
        .flat();

      for (const siblingNodeId of siblings) {
        const siblingNode = context.rootGetters['schemes/getNode'](
          assessmentDetail.schemeSlug,
          siblingNodeId
        ) as SchemeNodeModel;

        if (
          nodeRequirementsMet(siblingNode.dependencies, memberAssessments.complete) &&
          !(siblingNodeId in memberAssessments.complete)
        ) {
          // If the sibling is available, but not complete, then we mark it as in-progress.
          memberAssessments.available[siblingNodeId] = true;
          if (memberProgress) {
            memberProgress[siblingNodeId] = [0, 0];
          }
        }
      }
    }

    return [newScores, memberAssessments, memberProgress, newAwards];
  },

  updateAssessmentInDbStore: async (
    context: ActionContext<GroupMemberState, RootState>,
    assessmentDetail: AssessmentDetail
  ): Promise<void> => {
    const member = context.getters.memberById(assessmentDetail.groupId, assessmentDetail.memberUuid) as Member;

    const [newScores, memberAssessments, memberProgress, memberAwards]: [
      Scores,
      Assessments,
      Progress | undefined,
      Awards
    ] = await context.dispatch('applyAssessmentToMember', { assessmentDetail, member });

    await membersDb.updateAssessment(
      assessmentDetail.groupId,
      assessmentDetail.memberUuid,
      newScores,
      memberAssessments,
      memberProgress,
      memberAwards
    );

    member.assessments = memberAssessments;
    member.progress = memberProgress;
    member.awards = memberAwards;
    context.commit('updateGroupMember', {
      groupId: assessmentDetail.groupId,
      memberUuid: assessmentDetail.memberUuid,
      member,
    });
  },
  setMemberLegendCache: (
    context: ActionContext<GroupMemberState, RootState>,
    { memberUuid, legend, dob }: { memberUuid: string; legend: string; dob: string }
  ) => {
    context.commit('updateMemberLegendCache', { memberUuid, legend, dob });
  },
  clearMemberLegendCache: async (context: ActionContext<GroupMemberState, RootState>): Promise<void> => {
    context.commit('clearMemberLegendCache');
  },
};

/**
 * Available functions for code external to the store to retrieved this modules state properties values.
 * Can alter, calculate with or filter these values before return.
 *
 * @property getters - Object
 */
const getters = {
  itemsPerPage: (moduleState: GroupMemberState) => moduleState.itemsPerPage,
  groupMembers: (moduleState: GroupMemberState) => moduleState.items,
  currentPage: (moduleState: GroupMemberState) => moduleState.currentPage,
  total: (moduleState: GroupMemberState) => {
    return parseInt(String(moduleState.total), 10);
  },
  memberById: (moduleState: GroupMemberState) => (groupId: number, memberUuid: string): Member => {
    const members = moduleState.items as Array<Member>;
    return members.find((member: Member) => member.uuid === memberUuid && member.group_id === groupId) as Member;
  },
  isLoading: (moduleState: GroupMemberState) => moduleState.isLoading,
  sort: (moduleState: GroupMemberState) => moduleState.sort,
  sortDirection: (moduleState: GroupMemberState) => moduleState.sortDirection,
  selectedGroupMembers: (moduleState: GroupMemberState) => moduleState.selected,
  errors: (moduleState: GroupMemberState) => moduleState.errors,
  search: (moduleState: GroupMemberState) => moduleState.search,
  getMemberLegendCache: (moduleState: GroupMemberState) => (memberUuid: string, getDob: boolean) => {
    const cache = moduleState.memberLegendCache[memberUuid];
    if (!cache) {
      return null;
    }
    if (getDob) {
      return cache.dob;
    } else {
      return cache.legend;
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
