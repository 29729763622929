import { ref } from 'vue';

const online = ref(navigator.onLine);
export const useOnline = () => online;

// listen to the navigator events
window.addEventListener('offline', () => {
  online.value = false;
});

window.addEventListener('online', () => {
  online.value = true;
});
