import moment from 'moment';

/**
 * Returns a date or datetime string in the format DD/MM/YYYY.
 *
 * @param value - string, datetime passed from API.
 * @returns  string
 */
export default function formatDate(value: string): string {
  if (!value) {
    return '';
  }
  value = value.toString();
  // TODO: need to do some locale stuff here but for now UK date is fine
  return moment(value).format('DD/MM/YYYY');
}
