import { ErrorResponseBody } from './errorResponseBody';

export class ResponseError extends Error {
  public response: Response | null;
  public body: ErrorResponseBody;
  public error: string;
  public detail: any;

  constructor(response: Response | null, body: ErrorResponseBody) {
    super('ResponseError');
    this.response = response;
    this.body = body;

    this.error = body.error;
    try {
      this.detail = JSON.parse(body.detail);
    } catch (error) {
      this.detail = body.detail;
    }

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ResponseError.prototype);
  }
}
