import i18n from '@/i18n';
import { NodeType, SchemeNodeModel, DependencyArray } from '@/models/schemeNodeModel';
import { Member, AssessmentContents, Scores } from '@/models/member';
import { ColumnHeader } from '@/models/columnHeader';
import { AuthUser } from '@/models/authUser';
import { Club } from '@/models/club';
import moment from 'moment';

const isLevel = (node: SchemeNodeModel): boolean => node.t === NodeType.Level;
const isSkill = (node: SchemeNodeModel): boolean => node.t === NodeType.Skill;

const canContainNode = (target: SchemeNodeModel, node: SchemeNodeModel) => {
  // Check if the move is allowed, you can only move nodes to nodes that are able to contain them, ie, levels contain
  // everything. skill-groups contain skill-groups and skills, skills contain nothing.
  if (isLevel(target)) {
    return true;
  }

  if (isSkill(target)) {
    return false;
  }

  return !isLevel(node);
};

const isManualButtonId = (nodeId: string): boolean => nodeId !== undefined && nodeId.match(/\/manual$/) !== null;

const getManualButtonId = (nodeId: string) => `${nodeId}/manual`;

const nodeRequirementsMet = (dependencies: DependencyArray | [] | null, complete: AssessmentContents): boolean => {
  if (dependencies === null || dependencies.length !== 2 || !dependencies[0] || !dependencies[1]) {
    return true; // always available
  }

  const passed = dependencies[0].reduce(
    (cnt, requiredId) => cnt + (requiredId in complete && complete[requiredId] === true ? 1 : 0),
    0
  );
  return passed >= dependencies[1];
};

const findManualButtonNode = (node: SchemeNodeModel) => {
  const id = getManualButtonId(node.id);
  return node.children.find(c => c.id === id);
};

const isManualLevel = (node: SchemeNodeModel): boolean => {
  return (
    node.completion !== null &&
    node.completion[1] === 1 &&
    node.completion[0].length === 1 &&
    isManualButtonId(node.completion[0][0])
  );
};

const isAssessible = (node: SchemeNodeModel): boolean => node.assessment !== undefined && node.assessment[1] > 0;

const isManualOnlyLevel = (node: SchemeNodeModel): boolean => {
  if (!isManualLevel(node)) {
    return false;
  }
  const assessibleLevels = node.children.filter(c => isAssessible(c));
  return assessibleLevels && assessibleLevels.length === 1 && isManualButtonId(assessibleLevels[0].id);
};

const getPercentComplete = (levelNodes: Array<SchemeNodeModel>, memberScores: Scores): string => {
  if (!memberScores || !levelNodes) {
    return '0%';
  }
  // Should match classes.js calcClassProgress
  const getSkills = (node: SchemeNodeModel): Array<SchemeNodeModel> => {
    const next = node.children && node.children.length ? node.children.flatMap(getSkills) : [node];
    return next;
  };
  const skillNodes = levelNodes.flatMap(getSkills).filter(n => !isManualButtonId(n.id));

  // Get the % complete for each skill
  const schemeCompletion = skillNodes.reduce((sum, node) => {
    if (!node.assessment || !memberScores) {
      return sum;
    }
    // scores are 0 indexed, 0 = 'just started', assessment is [min, max, pass]
    // so a score of 0 and a pass of 3 means there are 4 levels, and we are at (0+1) / (3+1) = 0.25
    const score = node.id in memberScores ? parseInt(memberScores[node.id].assessment, 10) : -1;
    const skillCompletion = Math.min(1, (1 + score) / (1 + node.assessment[2])); // pass and above == 100%
    return sum + skillCompletion;
  }, 0);

  const percentComplete = Math.round((100 * schemeCompletion) / skillNodes.length);
  return `${percentComplete}%`;
};

const defaultNode = (type: NodeType, parentNode: SchemeNodeModel, defaultGroup: string): SchemeNodeModel => {
  const node: SchemeNodeModel = {
    id: '',
    children: [],
    hidden: false,
    completion: null,
    dependencies: parentNode.dependencies,
    content: [],
    t: type,
    title: '',
    is_owner: 1,
    parentId: parentNode.id,
  } as SchemeNodeModel;

  if (node.t === NodeType.Skill) {
    node.assessment = [0, 0, 0];
  } else {
    node.completion = [[], 0];
    node.group = defaultGroup;
  }

  return node;
};

// map on each node in the tree, returning a flat array. Excludes root node.
const flatMapTree = (tree: SchemeNodeModel, f: (node: SchemeNodeModel) => any): any[] =>
  tree && tree.id ? [...(tree.id === '/' ? [] : [f(tree)]), ...tree.children.map(c => flatMapTree(c, f)).flat()] : [];

// excludes the root node
const getNodeIdsFromTree = (tree: SchemeNodeModel): string[] => flatMapTree(tree, n => (n ? n.id : null));

// Returns a filtered copy of the tree. Includes the full path to any unfiltered nodes (all parents)
function filterTree(tree: SchemeNodeModel, f: (node: SchemeNodeModel) => boolean) {
  if (!tree || 'children' in tree === false) {
    return tree; // empty tree
  }
  const childFilter = (child: SchemeNodeModel) => {
    // shallow clone the children before filtering
    child.children = child.children.map(cc => ({ ...cc })).filter(childFilter);
    return f(child) || child.children.length;
  };

  // shallow copy the tree
  const copy = { ...tree };
  childFilter(copy);
  return copy;
}

// Runs a method on the leaves of a tree
const flatMapLeaves = (tree: SchemeNodeModel, f: (node: SchemeNodeModel) => any): any[] =>
  tree && tree.id
    ? [...(!tree.children.length ? [f(tree)] : []), ...tree.children.map(c => flatMapLeaves(c, f)).flat()]
    : [];

function getLookup(tree: SchemeNodeModel): Map<string, SchemeNodeModel> {
  // return a lookup table of [nodeId => node] for faster getNode
  const t = flatMapTree(tree, n => (n ? [n.id, n] : []));
  return new Map([['/', tree], ...t]);
}

function slowGetNode(tree: SchemeNodeModel, schemeNodeId: string): SchemeNodeModel {
  let activeNode = {} as SchemeNodeModel;
  const queue = [];
  queue.push(tree);

  while (queue.length > 0) {
    const currNode = queue.shift() as SchemeNodeModel;
    if (currNode.id === schemeNodeId) {
      activeNode = currNode;
      break;
    }
    if (currNode.children) {
      currNode?.children.forEach((childNode: SchemeNodeModel) => {
        queue.push(childNode);
      });
    }
  }
  return activeNode;
}

// Returns a list of levels and their children matching the filter (~opposite of filterTree)
function filterTreeChildren(tree: SchemeNodeModel, f: (node: SchemeNodeModel) => boolean): Array<string> {
  if (!tree || 'children' in tree === false) {
    return []; // empty tree
  }
  if (f(tree)) {
    return getNodeIdsFromTree(tree); // get the node IDs of all children and stop
  }
  return tree.children.map(child => filterTreeChildren(child, f)).flat();
}

function searchTree(tree: SchemeNodeModel, search: string) {
  const s = search.toLowerCase();
  return filterTree(
    tree,
    n =>
      'title' in n &&
      (n.title.toLowerCase().includes(s) ||
        n.content
          .filter(c => c.title === 'Description' && (c.value as string).toLowerCase())
          .join()
          .includes(s))
  );
}

const getAge = (dateString: string) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const getReadableDate = (dateString: string) => {
  const date = moment(dateString);
  if (date.isSame(moment(), 'day')) {
    return i18n.t('global.today');
  }
  return date.format('MMM Do YYYY');
};

const isSuperset = (set: Set<string>, subset: Set<string>) => {
  for (const elem of subset) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
};

const setIntersection = (setA: Set<string>, setB: Set<string>) => {
  const i = new Set();
  for (const elem of setB) {
    if (setA.has(elem)) {
      i.add(elem);
    }
  }
  return i;
};

const getForcePassed = (tree: SchemeNodeModel, member: Member) => {
  // a level is force passed if it has an assessment of 1, all of its children are also force-passed
  return filterTreeChildren(
    tree,
    node => isLevel(node) && node.id in member.scores && member.scores[node.id].assessment === '1'
  );
};

const hideUnusedHeaders = (headers: Array<ColumnHeader>, data: Array<any>, showStatus: boolean) => {
  // hide bg numbers if always null in all rows
  const hasNgoID = data.length && data.some(m => 'ngo_id' in m && m.ngo_id);
  // show status if there are non-active statuses OR we were passed showStatus
  const hasNonactiveStatuses = data.length && data.some(m => 'status' in m && m.status !== 'active');
  return headers.filter(
    h => (h.value !== 'ngo_id' || hasNgoID) && (h.value !== 'status' || showStatus || hasNonactiveStatuses)
  );
};

const sendGALoginEvent = (gtag: any, user: AuthUser, title: string, analyticsId: string | undefined) => {
  if (gtag) {
    const action = `${user.club.id} - ${user.club.name}`;
    gtag.event(action, {
      event_category: title,
      event_label: 'Login',
      value: 1, // Increment number of logins for this club
      send_to: analyticsId,
    });
  }
};

const sendGAVideoViewEvent = (gtag: any, videoName: string, appName: string, analyticsId: string | undefined) => {
  if (gtag) {
    gtag.event(videoName, {
      event_category: `${appName} video view`,
      event_label: 'Video view',
      value: 1, // Increment number of views
      send_to: analyticsId,
    });
  }
};

const updateCookieConsent = (appType: string, value: boolean) => {
  const cookieName = `${appType}PortalCookieConsent`;
  localStorage.setItem(cookieName, value ? 'true' : 'false'); // Must be a string
};

const getCookieConsent = (appType: string) => localStorage.getItem(`${appType}PortalCookieConsent`);

// Get keys from a string Enum (non numeric keys)
const stringEnumKeys = <O extends object, K extends keyof O = keyof O>(obj: O): K[] =>
  Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];

const arrayMove = (arr: Array<any>, oldIndex: number, newIndex: number) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr; // for testing
};

const getUserClubs = (clubs: Array<Club>, clubIds: Array<number | null>) => {
  return clubs
    .filter(c => clubIds.includes(c.id))
    .sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
};

const ageFormatted = (dob: string | undefined): string => {
  // Will throw NaN in case of error.
  if (!dob) {
    return '';
  }
  const totalMonths = moment().diff(dob, 'months');
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;
  if (!months && !years) {
    return '(1 Mth)';
  }
  if (!years) {
    return `(${months} Mths)`;
  }
  if (!months) {
    return `(${years} Yrs)`;
  }
  return `(${years} Yrs ${months} Mths)`;
};

const languageCodes: Record<string, string> = {
  'en-GB': 'English',
  'cy-GB': 'Cymraeg',
  'fr-FR': 'Français',
  'de-DE': 'Deutsch',
};

export {
  getLookup,
  filterTree,
  filterTreeChildren,
  searchTree,
  getNodeIdsFromTree, // TODO: most of these can be replaced by `return lookup`?
  flatMapTree,
  getAge,
  getReadableDate,
  isSuperset,
  setIntersection,
  isLevel,
  isSkill,
  isManualButtonId,
  isManualLevel,
  isAssessible,
  isManualOnlyLevel,
  getPercentComplete,
  getForcePassed,
  hideUnusedHeaders,
  sendGALoginEvent,
  updateCookieConsent,
  getCookieConsent,
  slowGetNode,
  flatMapLeaves,
  stringEnumKeys,
  arrayMove,
  defaultNode,
  findManualButtonNode,
  getManualButtonId,
  nodeRequirementsMet,
  sendGAVideoViewEvent,
  getUserClubs,
  canContainNode,
  ageFormatted,
  languageCodes,
};
