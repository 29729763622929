import Vue from 'vue';
import Club from './Club.vue';
import routes from './router/club';
import store from './store/club';
import pinia from './plugins/pinia';
import vuetify from './plugins/vuetify';
import GlobalAlert from './plugins/global-alert';
import formatDate from './filters/date';
import i18n from './i18n';
import VueHead from 'vue-head';
import VuetifyConfirm from 'vuetify-confirm';
import VueRouter from 'vue-router';
import { authGuard } from '@/services/auth';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

Vue.use(GlobalAlert);
Vue.use(VueHead);
Vue.use(VuetifyConfirm, { vuetify });

Vue.filter('formatDate', formatDate);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(authGuard(store));

if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_CLUB_GOOGLE_ANALYTICS_ID) {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_CLUB_GOOGLE_ANALYTICS_ID },
      bootstrap: false,
    },
    router
  );
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  pinia,
  render: h => h(Club),
}).$mount('#app');
