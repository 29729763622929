import Dexie from 'dexie';
import { Group } from '@/models/group';
import { Member } from '@/models/member';
import { GroupMember } from '@/models/groupMember';
import { LessonPlan, LessonPlanGroup } from '@/models/lessonPlan';
import { SyncType } from '@/models/syncType';
import { Setting } from '@/models/setting';
import { makeRequest, getLastOnlineCheck, setLastOnlineCheck } from '@/services/api-request';
import { EventBus } from '@/services/event-bus';
import { getApiUrl } from '@/consts';
import moment from 'moment';
import { watch } from 'vue';
import { useOnline } from '@/composables/use-online';
import { getValidRefreshToken, sendTokenToServiceWorker } from '../auth';

const bgSyncDb = new Dexie('workbox-background-sync');

export class StoreDb extends Dexie {
  syncs: Dexie.Table<{ id: number; member_sync_date: string; lessonPlan_sync_date: string }, number>;
  groups: Dexie.Table<Group, number>;
  members: Dexie.Table<Member, [number, string]>;
  groupMembers: Dexie.Table<GroupMember, [number, string]>;
  lessonPlans: Dexie.Table<LessonPlan, string>;
  lessonPlanGroups: Dexie.Table<LessonPlanGroup, string>;
  videos: Dexie.Table<{ id: string; buffer: ArrayBuffer }, string>;
  settings: Dexie.Table<Setting, string>;
  online = false;

  constructor() {
    super('StoreDb');
    // Define tables and indexes
    // https://dexie.org/docs/Version/Version.stores() only index what you will put in .where not images etc
    // See https://dexie.org/docs/Tutorial/Design#database-versioning for version upgrading.
    this.version(8).stores({
      syncs: 'id, member_sync_date, session_sync_date',
      groups: 'id, name, total_members, teacher_ngo_id',
      members: '[group_id+uuid], group_id, uuid, firstname, lastname',
      groupMembers: '[group_id+member_uuid], group_id, member_uuid',
      lessonPlans: 'uuid, title, owner, scheme_slug',
      lessonPlanGroups: 'uuid, title, scheme_slug',
      videos: 'id',
      settings: 'setting',
    });

    // The following lines are needed for it to work across typescipt using babel-preset-typescript:
    this.syncs = this.table('syncs');
    this.groups = this.table('groups');
    this.members = this.table('members');
    this.groupMembers = this.table('groupMembers');
    this.lessonPlans = this.table('lessonPlans');
    this.lessonPlanGroups = this.table('lessonPlanGroups');
    this.videos = this.table('videos');
    this.settings = this.table('settings');

    // try and fetch something from every store to make sure they are working, since the error is not triggering
    // until we try and retrieve something
    if (window.indexedDB) {
      let deleted = 0;
      this.tables.forEach(table => {
        if (!deleted) {
          table
            .limit(1)
            .first()
            .catch(e => {
              console.error('IndexedDB error', e);
              if (e.name === 'OpenFailedError') {
                // We were unable to open the database, in testing this was due to the following:
                // "UpgradeError Not yet support for changing primary key"
                // If we find such an issue, we nuke the database and start afresh.
                console.error('Unable to load indexeddb database - destroying database and starting anew');
                this.delete();
                deleted = 1;
              }
            });
        }
      });
    }

    const online = useOnline();

    watch(online, isOnline => {
      if (isOnline) {
        this.processUpdateQueue();
      } else {
        this.getRequestQueueLength();
      }
    });

    if (navigator instanceof Navigator && navigator.serviceWorker) {
      this.getRequestQueueLength();
      navigator.serviceWorker.onmessage = e => {
        if (e.data === 'offline') {
          online.value = false;
        }
        if (e.data === 'request-queue-updated') {
          this.getRequestQueueLength();
        }
      };
    }
  }

  async getSetting(s: string): Promise<string> {
    const setting = await this.settings.get(s);
    return setting ? JSON.parse(setting.value) : undefined;
  }

  async setSetting(s: string, v: any): Promise<string> {
    return this.settings.put({ setting: s, value: JSON.stringify(v) });
  }

  /**
   * @param {string} type Either member or session
   * Returns the last sync date stored against id 1
   */
  getSyncDate(type: string): Promise<string> {
    return this.syncs.get(1).then(result => {
      if (result) {
        if (type === 'member') {
          return result.member_sync_date;
        } else {
          return result.lessonPlan_sync_date;
        }
      } else {
        return '';
      }
    });
  }

  /**
   * Sets the last synced_date as the only record in row 1 id 1 of the table
   *
   * @param {string} syncDate
   * @param {string} type Either member or session, the sync date to set
   */
  async setSyncDate(syncDate: string, type: SyncType): Promise<number> {
    const exists = await this.syncs
      .where('id')
      .equals(1)
      .first();
    if (type === 'member') {
      if (exists) {
        return this.syncs.update(1, { member_sync_date: syncDate });
      } else {
        return this.syncs.put({ id: 1, member_sync_date: syncDate, lessonPlan_sync_date: '' }, 1);
      }
    } else {
      if (exists) {
        return this.syncs.update(1, { lessonPlan_sync_date: syncDate });
      } else {
        return this.syncs.put({ id: 1, lessonPlan_sync_date: syncDate, member_sync_date: '' }, 1);
      }
    }
  }

  /**
   * Check if we have synced our data already today
   * if we have then stick with what we have
   * if we haven't then refresh is needed
   * @param {string} type Either member or session, the sync date to check
   */
  async isSyncNeeded(type: SyncType): Promise<boolean> {
    // TODO: check if user has changed
    try {
      const storedSyncDate = await this.getSyncDate(type);
      if (storedSyncDate) {
        // check if its today
        const todayDate = moment().format('YYYY-MM-DD');
        return todayDate !== storedSyncDate;
      }
      return true;
    } catch (e) {
      console.error('Error fetching syncDate', e);
      return true; // might as well sync and reset everything as something has gone wrong!
    }
  }

  /**
   * Sends a message to the service worker to send its update queue
   * This should happen automatically but is here to be called
   * before we get fresh data from the api.
   */
  async processUpdateQueue(): Promise<boolean> {
    if (navigator instanceof Navigator && navigator.serviceWorker !== undefined && navigator.serviceWorker.controller) {
      let requestQueueLength = 0;
      requestQueueLength = await this.getRequestQueueLength();

      if (requestQueueLength > 0) {
        const refreshToken = getValidRefreshToken();
        if (refreshToken) {
          console.log('[StoreDb] processing request queue');
          sendTokenToServiceWorker(refreshToken);
          navigator.serviceWorker.controller.postMessage({ type: 'processUpdateQueue' });
        } else {
          console.error('Unable to process queue - no valid refresh token found');
        }
        return true;
      }
    }
    // unlikely to be here
    return false;
  }

  private async getRequestQueueLength() {
    let requestQueueLength = 0;
    try {
      if (!bgSyncDb.isOpen()) {
        await bgSyncDb.open(); // not using a schema, so need to open before we can use table()
      }
      requestQueueLength = await bgSyncDb.table('requests').count();
      EventBus.$emit('request-queue-length', requestQueueLength);
    } catch (e) {
      console.log('No background sync db exists as not needed yet');
    }
    return requestQueueLength;
  }

  async checkIsOnline(): Promise<boolean> {
    // If we cannot call status, then we are offline.
    // makeRequest will handle sending online/offline events depending on if the request succeeded or not.
    try {
      const checkNow = Date.now();
      const timeSinceLastCheck = checkNow - getLastOnlineCheck();
      // Don't check online status more than once every 0.5 seconds
      if (timeSinceLastCheck < 500) {
        return true;
      }
      setLastOnlineCheck(checkNow);
      const url = `${getApiUrl()}/status`;
      await makeRequest('GET', url, { cache: 'no-cache' }, false);
    } catch (err) {
      return false;
    }
    return true;
  }

  /**
   * Check if the db is ready
   * @param {string} type Either member or session, the sync date to check
   * @param {boolean} forceSync Sync anyway
   */
  async isReadyToLoad(type: SyncType, forceSync = false): Promise<boolean> {
    // NOTE: this runs on MOST navigations
    const isOnline = await this.checkIsOnline();
    if (!isOnline) {
      return false;
    }
    // post any offline updates before getting new data
    this.processUpdateQueue();

    let syncNeeded = forceSync;
    if (!syncNeeded) {
      syncNeeded = await this.isSyncNeeded(type);
    }

    if (syncNeeded) {
      await this.clearTables(type);
      return true;
    }
    return false;
  }

  /**
   * Clears the groups, members and group members tables
   * They don't depend on each other so can use async and
   * clear them all at same time
   */
  clearTables(type: SyncType | null = null): Promise<void[]> {
    const promises = [];
    if (type === null || type === 'lessonPlan') {
      promises.push(this.lessonPlans.clear(), this.lessonPlanGroups.clear());
    }
    if (type === null || type === 'member') {
      promises.push(this.groups.clear(), this.members.clear(), this.groupMembers.clear());
    }
    if (type === null) {
      promises.push(this.settings.clear(), this.syncs.clear(), this.videos.clear());
    }
    return Promise.all(promises);
  }
}

export const db = new StoreDb();
