import { authFetch, fetchJSON, getBaseUrl, retrieveToken } from '@/services/auth';
import { AuthResponse } from '@/models/authResponse';
import { ResponseError } from '@/models/responseError';
import { useOnline } from '@/composables/use-online';

let lastOnlineCheck = 0;

const getLastOnlineCheck = () => {
  return lastOnlineCheck;
};

const setLastOnlineCheck = (v: number) => {
  lastOnlineCheck = v;
};

const online = useOnline();
const notifyOffline = () => {
  online.value = false;
};

const notifyOnline = () => {
  online.value = true;
};

const makeRequest = async (
  method: 'GET' | 'PATCH' | 'DELETE' | 'PUT' | 'POST',
  url: string,
  options?: object,
  withAuth = true
): Promise<AuthResponse> => {
  // Add a unique request id header to each request so we know if the response has come from cache
  const requestId =
    Date.now().toString(36) +
    Math.random()
      .toString(36)
      .substring(2);
  const headers = { 'Content-Type': 'application/json', 'X-Request-Id': requestId };
  const opts = Object.assign({ method, headers }, options);
  const func = withAuth ? authFetch : fetchJSON;
  try {
    const fullUrl = url.startsWith('http') ? url : `${getBaseUrl()}${url}`;
    const { response, body } = await func(fullUrl, opts);

    if (response.ok) {
      // we go back online when we get a successful non cached response, so if we get a 404, we don't go offline,
      // but we would also not return online until we receive a 20X.
      const respRequestId = response.headers.get('X-Request-Id');
      if (respRequestId === requestId) {
        notifyOnline();
      }
      return { response, body };
    }

    if ([502, 503, 504].includes(response.status)) {
      // we don't go offline for 4XX or 500.
      notifyOffline();
    }

    // Bad request, make a response error -- then catch it again right away
    throw new ResponseError(response, body);
  } catch (error) {
    if (error instanceof TypeError) {
      // network error from fetch
      notifyOffline();
      throw new ResponseError(null, { error: 'Connection Error', detail: 'ERROR_FETCH' });
    }

    if (error instanceof ResponseError) {
      // API error
      throw error;
    }

    // unknown error
    throw new ResponseError(null, { error: 'Unknown Error', detail: 'UNKNOWN_ERROR' });
  }
};

export { makeRequest, retrieveToken, getLastOnlineCheck, setLastOnlineCheck };
