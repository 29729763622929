import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import SchemeNodeComponent from '@/components/coach/scheme/SchemeNode.vue';
import Groups from '@/components/coach/members/Groups.vue';
import i18n from '../i18n';

Vue.use(VueRouter);

const memberRoute = '/member-groups';
const memberRouteTitle = i18n.t('nav.groups');
const planningRoute = '/planning';
const planningRouteTitle = i18n.t('nav.planning');
const schemeRoute = '/scheme';
const schemeRouteTitle = i18n.t('nav.scheme');

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: memberRoute,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "coach-router-bundle" */ '../views/common/LoginPage.vue'),
  },
  {
    path: planningRoute,
    meta: {
      breadcrumbs: [{ text: planningRouteTitle, to: { path: planningRoute }, disabled: false, exact: true }],
    },
    component: () => import(/* webpackChunkName: "coach-router-bundle" */ '@/views/coach/Planning.vue'),
    children: [
      {
        path: '/',
        name: 'planning',
        props: _route => ({ schemeSlug: null }),
        component: () =>
          import(/* webpackChunkName: "coach-router-bundle" */ '@/components/coach/planning/PlanningList.vue'),
      },
      {
        path: ':schemeSlug',
        name: 'lesson-plans',
        props: route => ({ schemeSlug: route.params.schemeSlug }),
        component: () =>
          import(/* webpackChunkName: "coach-router-bundle" */ '@/components/coach/planning/PlanningList.vue'),
      },
      {
        path: ':schemeSlug/:lessonPlanUuid',
        name: 'lesson-plan',
        props: route => ({
          schemeSlug: route.params.schemeSlug,
          lessonPlanUuid: route.params.lessonPlanUuid,
        }),
        component: () =>
          import(/* webpackChunkName: "coach-router-bundle" */ '@/components/coach/planning/LessonPlan.vue'),
        meta: {
          breadcrumbs: [{ text: planningRouteTitle, to: { path: planningRoute }, disabled: false, exact: true }],
        },
      },
      {
        path: ':schemeSlug/group/:lessonPlanGroupUuid',
        name: 'lesson-plan-group',
        props: route => ({
          schemeSlug: route.params.schemeSlug,
          lessonPlanGroupUuid: route.params.lessonPlanGroupUuid,
        }),
        component: () =>
          import(/* webpackChunkName: "coach-router-bundle" */ '@/components/coach/planning/LessonPlanGroup.vue'),
        meta: {
          breadcrumbs: [{ text: planningRouteTitle, to: { path: planningRoute }, disabled: false, exact: true }],
        },
      },
    ],
  },
  {
    path: memberRoute,
    meta: {
      breadcrumbs: [
        {
          text: memberRouteTitle,
          i18nText: 'breadcrumb.member_route_title',
          to: { path: memberRoute },
          disabled: false,
          exact: true,
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (scheme.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "coach-router-bundle" */ '@/views/coach/Members.vue'),
    children: [
      {
        path: '/',
        name: 'member-groups',
        component: Groups,
        meta: {
          breadcrumbs: [
            // Home doesn't really make sense so don't need one for this path,
            {
              text: memberRouteTitle,
              i18nText: 'breadcrumb.member_route_title',
              to: { path: memberRoute },
              disabled: false,
              exact: true,
            },
          ],
        },
      },
      {
        path: ':groupId(\\d+)',
        name: 'groups-menu',
        component: () =>
          // NOTE: If we wanted to simplify this a bit more we could make this the parent of the subsequent routes, which
          // would wrap them in this component, which would allow us to handle all the group state in that one place,
          // (including loading from the DB, etc.) instead of doing it in each individual route, bypassing the store and
          // just using props, which might be easier to follow.
          import(/* webpackChunkName: "coach-router-bundle" */ '@/components/coach/members/GroupsMenu.vue'),
        props: route => ({ groupId: parseInt(route.params.groupId, 10) }),
        meta: {
          breadcrumbs: [
            {
              text: memberRouteTitle,
              i18nText: 'breadcrumb.member_route_title',
              to: { path: memberRoute },
              disabled: false,
              exact: true,
            },
            { text: ':groupId', to: { path: null }, disabled: true, exact: true },
          ],
        },
      },
      {
        path: ':groupId(\\d+)/register/',
        name: 'register',
        component: () =>
          import(/* webpackChunkName: "coach-router-bundle" */ '@/components/coach/members/Register.vue'),
        props: route => ({ groupId: parseInt(route.params.groupId, 10) }),
        meta: {
          breadcrumbs: [
            {
              text: memberRouteTitle,
              i18nText: 'breadcrumb.member_route_title',
              to: { path: memberRoute },
              disabled: false,
              exact: true,
            },
            { text: ':groupId', to: { path: memberRoute + '/:groupId' }, disabled: false, exact: true },
            { text: 'Register', to: { path: null }, disabled: true, exact: true },
          ],
        },
      },
      {
        path: ':groupId(\\d+)/assess-by-member/:name',
        name: 'assess-by-member-grades',
        beforeEnter(to, from, next) {
          // In the case of a page reload, we will not have the passed member UUID, take the user back to the
          // groups member list in this case.
          if (to.params.memberUuid) {
            next();
          } else {
            next({
              name: 'assess-by-member',
              params: { groupId: to.params.groupId },
            });
          }
        },
        component: () =>
          import(
            /* webpackChunkName: "coach-router-bundle" */ '@/components/coach/assessmentByMember/MemberAssessment.vue'
          ),
        props: route => ({ groupId: parseInt(route.params.groupId, 10), memberUuid: route.params.memberUuid }),
        meta: {
          breadcrumbs: [
            {
              text: memberRouteTitle,
              i18nText: 'breadcrumb.member_route_title',
              to: { path: memberRoute },
              disabled: false,
              exact: true,
            },
            { text: ':groupId', to: { path: memberRoute + '/:groupId' }, disabled: false, exact: true },
            {
              text: ':groupId',
              i18nText: 'breadcrumb.assess_by_member',
              to: { path: memberRoute + '/:groupId' + '/assess-by-member' },
              disabled: false,
              exact: true,
            },
            {
              text: 'Assessments',
              i18nText: 'breadcrumb.assessments',
              to: { path: null },
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: ':groupId(\\d+)/assess-by-member/',
        name: 'assess-by-member',
        component: () =>
          import(
            /* webpackChunkName: "coach-router-bundle" */ '@/components/coach/assessmentByMember/AssessByMemberMenu.vue'
          ),
        props: route => ({ groupId: parseInt(route.params.groupId, 10) }),
        meta: {
          breadcrumbs: [
            {
              text: memberRouteTitle,
              i18nText: 'breadcrumb.member_route_title',
              to: { path: memberRoute },
              disabled: false,
              exact: true,
            },
            { text: ':groupId', to: { path: memberRoute + '/:groupId' }, disabled: false, exact: true },
            {
              text: 'Assess by member',
              i18nText: 'breadcrumb.assess_by_member',
              to: { path: null },
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: ':groupId(\\d+)/assess-by-skill/:name',
        name: 'assess-by-skill-grades',
        beforeEnter(to, from, next) {
          // In the case of a page reload, we will not have the passed scheme node, take the user back to the
          // skill list in this case.
          if (to.params.selectedNodeId) {
            next();
          } else {
            next({
              name: 'assess-by-skill',
              params: { groupId: to.params.groupId },
            });
          }
        },
        component: () =>
          import(
            /* webpackChunkName: "coach-router-bundle" */ '@/components/coach/assessmentBySkill/SkillAssessment.vue'
          ),
        props: route => ({
          groupId: parseInt(route.params.groupId, 10),
          selectedNodeId: route.params.selectedNodeId,
          directLinkNodeId: route.params.directLinkNodeId,
          completeOnly: route.params.completeOnly,
        }),
        meta: {
          breadcrumbs: [
            {
              text: memberRouteTitle,
              i18nText: 'breadcrumb.member_route_title',
              to: { path: memberRoute },
              disabled: false,
              exact: true,
            },
            { text: ':groupId', to: { path: memberRoute + '/:groupId' }, disabled: false, exact: true },
            {
              text: ':groupId',
              i18nText: 'breadcrumb.assess_by_skill',
              to: { path: memberRoute + '/:groupId' + '/assess-by-skill' },
              disabled: false,
              exact: true,
            },
            {
              text: 'Assessments',
              i18nText: 'breadcrumb.assessments',
              to: { path: null },
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: ':groupId(\\d+)/assess-by-skill',
        name: 'assess-by-skill',
        component: () =>
          import(
            /* webpackChunkName: "coach-router-bundle" */ '@/components/coach/assessmentBySkill/AssessBySkill.vue'
          ),
        props: route => ({ groupId: parseInt(route.params.groupId, 10) }),
        meta: {
          breadcrumbs: [
            {
              text: memberRouteTitle,
              i18nText: 'breadcrumb.member_route_title',
              to: { path: memberRoute },
              disabled: false,
              exact: true,
            },
            { text: ':groupId', to: { path: memberRoute + '/:groupId' }, disabled: false, exact: true },
            {
              text: 'Assess by skill',
              i18nText: 'breadcrumb.assess_by_skill',
              to: { path: null },
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: ':groupId(\\d+)/badges',
        name: 'badges',
        component: () => import(/* webpackChunkName: "coach-router-bundle" */ '@/components/coach/members/Badges.vue'),
        props: route => ({ groupId: parseInt(route.params.groupId, 10) }),
        meta: {
          breadcrumbs: [
            {
              text: memberRouteTitle,
              i18nText: 'breadcrumb.member_route_title',
              to: { path: memberRoute },
              disabled: false,
              exact: true,
            },
            { text: ':groupId', to: { path: memberRoute + '/:groupId' }, disabled: false, exact: true },
            {
              text: 'Badges',
              i18nText: 'breadcrumb.badges',
              to: { path: null },
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: ':groupId(\\d+)/register/',
        name: 'alerts',
        component: () =>
          import(/* webpackChunkName: "coach-router-bundle" */ '@/components/coach/members/MemberAlerts.vue'),
        props: route => ({ groupId: parseInt(route.params.groupId, 10) }),
        meta: {
          breadcrumbs: [
            {
              text: memberRouteTitle,
              i18nText: 'breadcrumb.member_route_title',
              to: { path: memberRoute },
              disabled: false,
              exact: true,
            },
            { text: ':groupId', to: { path: memberRoute + '/:groupId' }, disabled: false, exact: true },
            { text: 'Alerts', to: { path: null }, disabled: true, exact: true },
          ],
        },
      },
    ],
  },
  {
    path: schemeRoute,
    component: () => import(/* webpackChunkName: "coach-router-bundle" */ '@/views/coach/Scheme.vue'),
    meta: {
      breadcrumbs: schemeRouteTitle,
    },
    children: [
      // Sub paths component will be rendered inside Schemes's <router-view>
      {
        path: '',
        name: 'scheme',
        component: () =>
          import(/* webpackChunkName: "coach-router-bundle" */ '@/components/coach/scheme/SchemeList.vue'),
        props: () => ({ routeName: 'scheme-page' }),
      },
      {
        path: ':schemeSlug',
        name: 'scheme-page',
        component: SchemeNodeComponent,
        props: route => ({ schemeSlug: route.params.schemeSlug, schemeNodeId: '/' }),
      },
      {
        path: ':schemeSlug/:schemeNodeId+',
        component: SchemeNodeComponent,
        props: route => ({ schemeSlug: route.params.schemeSlug, schemeNodeId: '/' + route.params.schemeNodeId }),
      },
    ],
  },
  {
    path: '/offline',
    name: 'offline',
    component: () => import(/* webpackChunkName: "coach-router-bundle" */ '@/views/coach/OfflineContent.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "coach-router-bundle" */ '../views/coach/AboutCoach.vue'),
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "coach-redirect" */ '@/components/common/PostOAuthRedirect.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "common-logout" */ '../views/common/LogoutPage.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "common-error" */ '@/views/common/ErrorPage.vue'),
  },
  {
    path: '/switch-user',
    name: 'switch-user',
    component: () => import(/* webpackChunkName: "coach-router-bundle" */ '../views/coach/SwitchUser.vue'),
  },
  {
    path: '*',
    redirect: '/error',
  },
];

export { memberRoute, planningRoute, schemeRoute, routes };
